import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AudioLines, Mic, Volume2, VolumeOff, StopCircleIcon, Loader } from 'lucide-react';
import { getKeys, logError, promiseRunTextLLM, runTextLLMMini, runLLMMiniReasonForVisitQuestion, getAuth, getIntakeAuth, addIntakePatient, convertMarkdownToHtml, htmlToText, getUrlParameter } from './db';

import MicrophoneSlashIcon from './MicrophoneSlashIcon.jsx'; // Adjust this import path as necessary
import { textToSpeech, connectToDeepgram, uploadAudio } from './deepgramws';
import AnimatedWaveLine from './AnimatedWaveLine';

import moment from "moment";

function formatDateWithMoment(date) {
    return moment(date).format("MM/DD/YY hh:mm A");
}

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
// Generic and flexible data structure
const formData = {
    title: "Reason for Visit",
    steps: [
        {
            id: 1,
            question: `Hi there! What brings you to the doctors office?\nI’ll share all of these details with your healthcare provider.`,
            questionVoiceModeAdditions: false,
            type: "multiselect",
            options: [
                { id: 1, name: "Sick visit", category: "Preventive Care" },
                { id: 2, name: "Flu-like symptoms", category: "Acute Illness" },
                { id: 3, name: "Chronic condition management", category: "Ongoing Care" },
                { id: 4, name: "Medication refill", category: "Prescription" },
                { id: 5, name: "Vaccination", category: "Preventive Care" },
                { id: 6, name: "Physical injury", category: "Acute Condition" },
                { id: 7, name: "Mental health concerns", category: "Mental Health" },
                { id: 8, name: "Unexplained pain", category: "Symptom Evaluation" },
                { id: 9, name: "Follow-up appointment", category: "Ongoing Care" },
                { id: 10, name: "Screening or lab tests", category: "Diagnostic" },
                { id: 11, name: "Other", category: "Miscellaneous" }
                // ... add more ingredients as needed
            ]
        }
    ]
};

let isListening = false;
let deepgram = undefined;
let connection = undefined;
let globalStream = null; // A place to store the stream globally
let globalMicrophone = null; // A place to store the MediaRecorder globally
let API_DEEPGRAM_KEY = undefined;
let CURRENT_VOICE_RESPONSE = "";
let audioChunks = [];
let mimeType = "audio/webm; codecs=opus";



const OptionSelector = React.memo(({ option, isSelected, onChange, type, name }) => (
    <label className="label cursor-pointer justify-start gap-2">
        <input
            type={type.toLowerCase() === "multiselect" ? "checkbox" : "radio"}
            name={name}
            className={type.toLowerCase() === "multiselect" ? "[--chkfg:white] checkbox checkbox-primary" : "radio radio-primary"}
            checked={isSelected}
            onChange={() => onChange(option.id)}
        />
        <span className="label-text text-xl">{option.name}</span>
    </label>
));

const ProgressBar = ({ currentStep, totalSteps }) => (
    <progress
        className="progress progress-primary w-full"
        value={(currentStep / totalSteps) * 100}
        max="100"
    ></progress>
);


const IntakeContent = () => {
    const [selections, setSelections] = useState({});
    const [result, setResult] = useState('');
    const [isVoiceMode, setIsVoiceMode] = useState(false);
    const [voiceResponses, setVoiceResponses] = useState({});

    const [otherDetails, setOtherDetails] = useState('');
    const [uploadingAudio, setUploadingAudio] = useState(false);


    const [currentStep, setCurrentStep] = useState(1);
    const [currentStepData, setCurrentStepData] = useState(formData.steps[0]);
    const stepRef = useRef(currentStep);

    const [recognizedText, setRecognizedText] = useState('Say something, press stop when finished');
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [showMic, setShowMic] = useState(false);
    const [showWaveform, setShowWaveform] = useState(false);

    const [isLoading, setIsLoading] = useState(false);


    const audioRef = useRef(null);
    const playAudio = useCallback(async (text) => {
        try {
            const audioBlob = await textToSpeech(text);
            const audioUrl = URL.createObjectURL(audioBlob);
            if (audioRef.current) {
                audioRef.current.src = audioUrl;
                await audioRef.current.play();
            }
        } catch (error) {
            console.error("Error playing audio:", error);
        }
    }, []);

    const handleAudioEnded = useCallback(() => {
        setIsAudioPlaying(false);
        // Add any additional logic you want to execute when audio finishes
        if (stepRef.current <= formData.steps.length) {
            setShowMic(true);
        }
    });




    useEffect(() => {
        stepRef.current = currentStep;
    }, [currentStep]);

    // Add this new function to get more questions
    const getMoreQuestions = async (reasonForVisit) => {
        try {
            const moreFormData = await runLLMMiniReasonForVisitQuestion(reasonForVisit, function (inProgressResponse) { });
            console.log('More Form Data:', moreFormData);

            const currentLastId = formData.steps.length;

            const updatedMoreFormData = moreFormData.steps.map((step, index) => ({
                ...step,
                id: currentLastId + index + 1
            }));

            formData.steps = formData.steps.concat(updatedMoreFormData);

        } catch (error) {
            console.error("Error getting more questions:", error);
        }
    };

    const getOptionExampleForVoice = function (stepId) {
        const step = formData.steps.find(s => s.id === stepId);
        const options = step.options.map(opt => opt.name);
        let exampleText = options.join(', ');

        if (exampleText.toLocaleLowerCase().indexOf('yes') > -1 && exampleText.toLocaleLowerCase().indexOf('no') > -1 && options.length == 2) {
            return 'Respond with yes or no';
        }
        else {
            return ' For example:' + exampleText;
        }

    }

    const getResponse = (stepId) => {
        const step = formData.steps.find(s => s.id === stepId);
        const question = step.question;
        let answer;

        if (isVoiceMode) {
            answer = voiceResponses[stepId] || '';
        } else {
            if (Array.isArray(selections[stepId])) {
                // Multiselect case
                const selectedOptions = (selections[stepId] || [])
                    .map(id => step.options.find(opt => opt.id === id))
                    .filter(Boolean);
                answer = selectedOptions.map(opt => opt.name).join(', ');
            } else {
                // Singleselect case
                if (step.options) {
                    const selectedOption = step.options.find(opt => opt.id === selections[stepId]);
                    answer = selectedOption ? selectedOption.name : '';
                }
            }
        }

        return { question, answer, type: step.type, questionVoiceModeAdditions: step.questionVoiceModeAdditions };
    };

    const checkForValidAnswer = async (stepId) => {

        // let questionAnswer = getResponse(stepId);

        // console.log('questionAnswer.answer:', questionAnswer)
        // let isValid = await runTextLLMMini('Is the following answer a valid answer to the question. Respond only with yes or no. Question:"' + questionAnswer.question + '" Answer: "' + questionAnswer.answer + '"', function (inProgressResponse) { })

        // console.log('isValid:', isValid);

        // return isValid.toLocaleLowerCase().indexOf('yes') > -1;

        return true

    }

    const checkForValidQuestion = async (stepId) => {
        let questionAnswersDialog = [];
        for (let i = 1; i <= formData.steps.length; i++) {
            let currentResponse = getResponse(i);
            if (currentResponse.answer) {
                questionAnswersDialog.push({ question: currentResponse.question, answer: currentResponse.answer });
            }

        }

        let conversationText = questionAnswersDialog.map(qa => qa.question + ' ' + qa.answer).join(',');

        let questionAnswer = getResponse(stepId);

        let isValid = await runTextLLMMini('Given the following questions and answers:"' + conversationText + " is this question '" + questionAnswer.question + "' still a validate question? Respond only with yes or no.", function (inProgressResponse) { })

        console.log('isValid:', isValid);

        return isValid.toLocaleLowerCase().indexOf('yes') > -1;

    }

    const handleSelectionChange = useCallback((stepId, optionId) => {
        setSelections(prevSelections => {
            const step = formData.steps.find(s => s.id === stepId);
            if (step.type.toLowerCase() === "multiselect") {
                const current = prevSelections[stepId] || [];
                return {
                    ...prevSelections,
                    [stepId]: current.includes(optionId)
                        ? current.filter(id => id !== optionId)
                        : [...current, optionId]
                };
            } else {
                // For single select, we directly set the optionId
                return { ...prevSelections, [stepId]: optionId };
            }
        });
    }, []);




    const generateResponse = async () => {
        setUploadingAudio(true);
        console.log('Voice Responses:', voiceResponses);
        console.log('Selections:', selections);

        let questionAnswersDialog = [];
        for (let i = 1; i <= formData.steps.length; i++) {
            let currentResponse = getResponse(i);
            questionAnswersDialog.push({ question: currentResponse.question, answer: currentResponse.answer });
        }

        let conversationText = questionAnswersDialog.map(qa => qa.question + ' ' + qa.answer).join(',');

        let llmResponse = await runTextLLMMini('Create pre-visit summary from conversation: ' + conversationText, function (inProgressResponse) {
            setResult(inProgressResponse);
        })

        setResult(llmResponse);

        const newPatient = {
            name: '',
            clinicalNote: "Placeholder",
            appointmentTimestamp: new Date().toISOString(),
            date: formatDateWithMoment(new Date()),
            history: htmlToText(convertMarkdownToHtml(llmResponse)),
            language: 'en',
            ehrId: '',
            isDictation: false
        };



        const id = await addIntakePatient(getUrlParameter('intake'), newPatient);

        setUploadingAudio(false);
        return llmResponse

    };



    const getVoiceSelectionExamples = (steps) => {
        const examples = steps.map(step => {
            return step.question;
        });

        return examples;
    }

    const toggleVoiceMode = useCallback(() => {
        setIsVoiceMode(prev => {
            if (!prev) {
                const currentQuestion = formData.steps[currentStep - 1].question;
                let audioQuestion = currentQuestion;

                if (formData.steps[currentStep - 1].questionVoiceModeAdditions) {
                    audioQuestion += formData.steps[currentStep - 1].questionVoiceModeAdditions//"When you see the microphone button, share your reason for visit and press it when finished.";
                }

                playAudio(audioQuestion);
            }
            return !prev;
        });
        setCurrentStep(1);
        setResult('');
        setRecognizedText('');
        setSelections({});




    }, [playAudio, currentStep]);


    const updatePatientResponse = (text) => {

        CURRENT_VOICE_RESPONSE += text;

        setRecognizedText(prevText => {
            const updatedText = prevText + ' ' + text;
            setVoiceResponses(prev => ({
                ...prev,
                [stepRef.current]: updatedText.trim()
            }));
            return updatedText;
        });
    };

    function openMic(errorAccess) {
        if (isListening) {
            return;
        }


        let handleStream = () => {
            // Call open microphone
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {

                    const options = { mimeType: "audio/webm; codecs=opus" };
                    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                        const types = [
                            "audio/webm;codecs=opus",
                            "audio/webm;codecs=vorbis",
                            "audio/webm",
                            "audio/ogg",
                            "audio/ogg;codecs=opus",
                            "audio/ogg;codecs=vorbis",
                            "audio/wav",
                            "audio/mp4;codecs=mp4a",
                            "audio/mp4;codecs=avc1",
                            "audio/mp4",
                        ];
                        for (let i = 0; i < types.length; i++) {
                            if (MediaRecorder.isTypeSupported(types[i])) {
                                options.mimeType = types[i];
                                mimeType = types[i];
                                break;
                            }
                        }
                    }

                    globalMicrophone = new MediaRecorder(stream, options);
                    globalStream = stream; // Store the stream globally
                    globalMicrophone.start(500);
                    globalMicrophone.onstart = () => {

                        isListening = true;
                        setShowMic(true);
                        setShowWaveform(true);

                    };
                    globalMicrophone.onstop = () => {
                        isListening = false;
                        setShowWaveform(false);
                    };
                    globalMicrophone.ondataavailable = (e) => {
                        audioChunks.push(e.data);

                    };
                })
                .catch((error) => {
                    logError("Error accessing the microphone:", error);

                    isListening = false;
                });
        };

        if (!isListening) {
            setTimeout(handleStream, 100);
        }
    }

    async function closeMic() {
        try {
            isListening = false;

            const audioBlob = new Blob(audioChunks, { type: mimeType });
            setUploadingAudio(true);

            const data = await uploadAudio(mimeType, API_DEEPGRAM_KEY, audioBlob);
            let text = data.results.channels[0].alternatives[0].transcript;


            audioChunks.length = 0; // Assuming audioChunks is accessible in this scope
            console.log("Audio uploaded");

            await updatePatientResponse(text);
        } catch (error) {
            console.error("Error uploading audio:", error);
        }
    }


    const toggleListening = async () => {
        setShowMic(false);

        if (isListening) {

            if (globalMicrophone) {
                globalMicrophone.stop();
            }
            if (globalStream) {
                globalStream.getTracks().forEach(track => track.stop());
            }

            setSelections({
                ...selections,
                [currentStep]: CURRENT_VOICE_RESPONSE
            });

            await closeMic();
            setShowMic(false);
            // Clear recognized text for the next step
            await timeout(3000);
            setUploadingAudio(false);



            // Play audio for the next question or result
            if (currentStep < formData.steps.length || currentStep === 1) {
                const currentQuestion = formData.steps[currentStep - 1].question;

                let isValid = true;

                if (currentStep != 1) {
                    isValid = await checkForValidAnswer(currentStep);
                }

                if (isValid) {
                    await handleNext();
                    let nextQuestion = formData.steps[currentStep].question;
                    let examples = getOptionExampleForVoice(currentStep + 1);

                    playAudio(`${nextQuestion} ${examples}`).then(async () => {
                    });
                }
                else {
                    let currentResponse = getResponse(currentStep);
                    let examples = getOptionExampleForVoice(currentStep);


                    playAudio(`I'm not sure if ${currentResponse.answer} is a valid answer to my question. Please try responding with an answer ${examples}`).then(() => {
                    });
                }


            } else {
                let isValid = await checkForValidAnswer(currentStep);
                if (isValid) {
                    isListening = false;
                    if (globalMicrophone) {
                        globalMicrophone.stop();
                    }
                    if (globalStream) {
                        globalStream.getTracks().forEach(track => track.stop());
                    }
                    setCurrentStep(prev => prev + 1);

                    await generateResponse();
                    playAudio("Here's your summary that I will share with your doctor. Looking forward to your visit.")

                }
                else {
                    let currentResponse = getResponse(currentStep);

                    playAudio(`I'm not sure if ${currentResponse.answer} is a valid answer to my question. Please try again.`).then(() => {
                    });
                }

            }
        } else {
            setRecognizedText('');
            CURRENT_VOICE_RESPONSE = "";
            openMic(() => {
                console.error('Error accessing the microphone');
            });
        }

        setRecognizedText('');
        CURRENT_VOICE_RESPONSE = "";
    };

    // Modify the handleNext function to include the call to getMoreQuestions
    const handleNext = async () => {
        let currentQuestion = getResponse(currentStep);
        let isOther = false;

        if (currentStep === 1 && (currentQuestion.answer === 'Other' || currentQuestion.answer === 'Sick visit')) {
            isOther = true;

            formData.steps.push({
                id: currentStep + 1,
                question: "Can you provide more details about your reason for visiting?",
                type: "text-other"
            });


        }

        if (isVoiceMode) {

            if (currentStep === 1) {
                setUploadingAudio(true);
                await getMoreQuestions(CURRENT_VOICE_RESPONSE);
                setUploadingAudio(false);
            }
        }
        else {
            if (!isOther) {
                if (currentQuestion.type === 'text-other') { //probably other

                    setUploadingAudio(true);
                    await getMoreQuestions(otherDetails);
                    setSelections({
                        ...selections,
                        [currentStep]: otherDetails
                    });

                    setUploadingAudio(false);
                }
                else if (currentStep === 1) {

                    setUploadingAudio(true);
                    await getMoreQuestions(currentQuestion.answer);
                    setSelections({
                        ...selections,
                        [currentStep]: otherDetails
                    });

                    setUploadingAudio(false);
                }
            }
        }



        if (currentStep < formData.steps.length) {
            setCurrentStep(prev => {
                const nextStep = prev + 1;
                if (isVoiceMode) {
                    const nextQuestion = formData.steps[nextStep - 1].question;
                    playAudio(nextQuestion);
                }
                return nextStep;
            });

            setCurrentStepData(formData.steps[currentStep]);

        } else {

            setCurrentStep(prev => {
                const nextStep = prev + 1;
                return nextStep;
            });

            generateResponse();
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setResults('');
            setCurrentStep(prev => {
                const prevStep = prev - 1;
                if (isVoiceMode) {
                    const prevQuestion = formData.steps[prevStep - 1].question;
                    playAudio(prevQuestion);
                }
                return prevStep;
            });
        }
    };


    useEffect(() => {
        return () => {
            if (globalMicrophone) {
                globalMicrophone.stop();
            }
            if (globalStream) {
                globalStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        const initializeKeys = async () => {
            let existingKeyData = await getKeys();

            if (!existingKeyData) {
                existingKeyData = await getAuth();
            }

            if (!existingKeyData || !existingKeyData.audio) {
                existingKeyData = await getIntakeAuth();
            }

            API_DEEPGRAM_KEY = existingKeyData.audio;


        };

        initializeKeys();

    }, []);


    return (
        <div className="max-w-2xl pl-4 pr-4 mx-auto bg-base-100">



            <ProgressBar currentStep={currentStep} totalSteps={formData.steps.length + 1} />
            {currentStep === 1 && (
                <img src="https://s3.amazonaws.com/ui.healthnote.com/images/client/cs-header-v2.png" alt="HealthNote" className="w-full mt-3 mb-4 -ml-2 h-20 max-w-[300px]  object-cover" />
            )}

            {isLoading && <progress className="progress w-56"></progress>}
            {!isLoading && (
                <div>
                    <div className="mb-4 flex justify-start items-center">

                    </div>
                    {currentStep <= formData.steps.length && (
                        <div className="mb-4 flex justify-start items-center">
                            <button
                                onClick={toggleVoiceMode}
                                className={`btn btn-circle btn-sm mr-2 ${isVoiceMode ? 'btn-primary' : 'btn-ghost'}`}
                            >
                                {isVoiceMode ? <Volume2 className="h-4 w-4" /> : <VolumeOff className="h-4 w-4" />}
                            </button>
                            <h2 className="text-2xl font-semibold mb-2">{currentStepData.question}</h2>
                            {isVoiceMode && (<p className='whitespace-pre-line'>{currentStepData.questionVoiceModeAdditions}</p>)}
                        </div>
                    )}

                    {isVoiceMode ? (
                        <div className="mb-4 text-center">
                            <div className="mt-4 mb-4">
                                <span>{recognizedText}</span>
                            </div>

                            {!uploadingAudio && (
                                <div>
{/* 
                                    <p className="m-5  text-xl opacity-60  text-base-content">
                                        {isListening ? "Please speak freely and include as many details as you want the doctor to know." : "Press mic to say and stop when finished"}
                                    </p> */}
                                    <div className='arrow-target arrow-active'>
                                        {!isListening && (<img className="arrow-microphone arrow-right" src="https://s3.amazonaws.com/ui.healthnote.com/images/ui-assets/arrow.svg"></img>)}

                                        <button
                                            onClick={toggleListening}
                                            className={`btn btn-lg ${isListening ? 'btn-error' : 'btn-warning'}`}
                                        >
                                            {!isListening && (<Mic className="h-6 w-6" />)}
                                            {isListening && (<StopCircleIcon className="h-6 w-6" />)}

                                            {`${!isListening ? 'Start' : 'Tap when done'}`}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {uploadingAudio && (
                                <progress className="progress w-56"></progress>
                            )}


                        </div>
                    ) : (
                        <div>
                            {!uploadingAudio && !isVoiceMode && currentStep <= formData.steps.length && (
                                <div className="mb-4">
                                    {currentStepData.type.toLowerCase() === "multiselect" || currentStepData.type.toLowerCase() === "singleselect" ? (
                                        <div className="max-h-60 overflow-y-auto border border-base-300 rounded p-2">
                                            {currentStepData.options.map((option) => (
                                                <OptionSelector
                                                    key={option.id}
                                                    option={option}
                                                    isSelected={
                                                        currentStepData.type.toLowerCase() === "multiselect"
                                                            ? (selections[currentStepData.id] || []).includes(option.id)
                                                            : selections[currentStepData.id] === option.id
                                                    }
                                                    onChange={(optionId) => handleSelectionChange(currentStepData.id, optionId)}
                                                    type={currentStepData.type}
                                                    name={`option-${currentStepData.id}`}
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <textarea
                                            className="textarea textarea-bordered w-full"
                                            value={otherDetails}
                                            onChange={(e) => setOtherDetails(e.target.value)}
                                            placeholder="Please provide more details..."
                                            rows={4}
                                        />
                                    )}
                                </div>
                            )}
                            {uploadingAudio && (
                                <progress className="progress w-56"></progress>
                            )}
                            {!uploadingAudio && !result && (
                                <div>
                                    <div className="flex justify-between" >
                                        <button
                                            onClick={handleBack}
                                            className="btn btn-outline btn-disabled"
                                        >
                                            Back
                                        </button>

                                        <button
                                            onClick={handleNext}
                                            className="btn btn-warning"
                                        >
                                            {currentStep === formData.steps.length && currentStep !== 1 && formData.steps[currentStep - 1].type != 'text-other' ? 'Finish' : 'Next'}
                                        </button>

                                    </div>
                                    <div className='overflow-visible h-0 relative'>
                                        {currentStep <= formData.steps.length && (
                                            <div className='arrow-active h-0 overflow-visible'>
                                                <img className="arrow arrow-right" src="https://s3.amazonaws.com/ui.healthnote.com/images/ui-assets/arrow.svg"></img>

                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                    {result && (
                        <div className="mt-4 p-4 border rounded bg-base-200">
                            <p className="whitespace-pre-line" dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(result) }}></p>
                        </div>
                    )}
                </div>
            )}

            <audio
                ref={audioRef}
                className="hidden"
                onEnded={handleAudioEnded}
            />
            {showWaveform && (
                <AnimatedWaveLine />

            )}
        </div>
    );
};

export default IntakeContent;