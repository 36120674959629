import Dexie from "dexie";
import mixpanel from 'mixpanel-browser';
import { OpenAIClient, AzureKeyCredential } from "@azure/openai";
//...
import * as Sentry from "@sentry/react";
import { extractSimpleTextTitles } from "./prompt.formatting";
import { Logout } from "@mui/icons-material";
import {initMediaMock} from "./db.mic.js";



const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('ext')) {
    // ext exists
    initMediaMock();
}


window.addEventListener("message", (event) => {
  // Check if the message is from the iOS web view
  if (event.origin === "com.healthnote.listen.beta" && event.data) {
    // Process the received data
    const receivedData = event.data;
    console.log("Received data from iOS web view:", receivedData);
  }
});

function extractJsonObject(str) {
  // Remove the code block markers and any leading/trailing whitespace
  const jsonString = str.replace(/^```json/, '').replace(/```$/, '').trim();
  
  try {
    // Parse the JSON string into a JavaScript object
    const jsonObject = JSON.parse(jsonString);
    return jsonObject;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
}


export async function generatePromptFromText(text) {

  let prompt = await promiseRunTextLLM("Give me a prompt template in a text block for:'"+text+"' here is an example example of a prompt for generating a SOAP template:\n<instruction>Output HTML code block. Do not use pronouns but use Pt instead. </instruction>. Use H3 tags for titles.Summarize it in a clinical SOAP NOTE from conversation translate to english. Use medical abbreviation and medical terminology. Include every comprehensive detail from conversation. Be as detailed as possible. Include all mentions of medications, symptoms, and other relevant information.If <content/> is NOT in conversation, use N/A. Output HTML code block. Replace <content/> with conversation related to that section. Output:<h3>Subjective</h3><p><content>full sentences Subjective paragraph</content></p><h3>Objective</h3><p><content>full sentences Objective paragraph</content></p><h3>Assessment</h3><p><content>full sentences Assessment paragraph</content></p><h3>Plan</h3><p><content>full sentences Plan paragraph</content></p><h3>Recommended ICD-10 codes</h3><p><content><ul><li>- • item 1</li></ul></content></p>\nConversation\n ")

  console.log("LLM output", prompt);
  return prompt;
}

export function promiseRunTextLLM(input) {
  return new Promise((resolve, reject) => {
    runTextLLM(
      input,
      (inprogress) => {
        // Handle in-progress updates if needed
        // console.log("In progress", inprogress);
      },
      (completed) => {
        resolve(completed);
      }
    );
  });
}

export function getSavedNoteURL(patient){
  try{
    let host = 'https://storage.cloud.google.com/healthnote-listen-prod-phi'

    if(window.location.hostname === "listen.healthnote.com"){
      host = 'https://storage.cloud.google.com/healthnote-listen-prod-phi'
    }
    else{
      host = 'https://storage.cloud.google.com/healthnote-listen-data'
    }
  
    let userInfo = getCookie("info");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      return `${host}/${userInfo.id}/${patient.ds_id}.json`
    }
  }
  catch(e){
  }

  return ""
}

function convertDateFormat(isoString) {
  const date = new Date(isoString);
  
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = String(date.getUTCFullYear()).slice(-2);
  
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  
  return `${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
}

export const checkMicroPhonePermission = async () => {
  const profile = await db.profile.toCollection().first();
  return !!(profile && profile.enabledMicrophone);
};

export const setMicroPhonePermission = async (enabled) => {
  db.profile.toCollection().first().then((profile) => {
    if (profile) {
      db.profile.update(profile.id, { enabledMicrophone: enabled });
    }
  });
}


export const checkDatabaseExists = async () => {
  const dbName = "HNAmbientDatabase";

  try {
    const exists = await Dexie.exists(dbName);
    if (exists) {
      console.log(`Database "${dbName}" already exists.`);
      // Open the existing database
      const db = new Dexie(dbName);
      // ... configure and use the database
    } else {
      console.log(
        `Database "${dbName}" does not exist. Creating new database.`
      );
      // Create and configure new database
      const db = new Dexie(dbName);
      // ... define schema and initialize the database
    }
  } catch (error) {
    console.error("Error checking database existence:", error);
  }
};



// // Record amount of memory used
// Sentry.setMeasurement("memoryUsed", 123, "byte");

// // Record time when Footer component renders on page
// Sentry.setMeasurement("ui.footerComponent.render", 1.3, "second");

// // Record amount of times localStorage was read
// Sentry.setMeasurement("localStorageRead", 4);
// Increment a counter by one for each button click.

// Sentry.metrics.increment("button_click", 1, {
//   tags: { browser: "Firefox", app_version: "1.0.0" },
// });
// const scope = new Sentry.Scope();
// scope.setTag("section", "articles");
// Sentry.captureException(new Error("something went wrong"), scope);

const db = new Dexie("HNAmbientDatabase");
db.version(1).stores({
  profile: "++id, alertClosed, hasSubmittedHubspotForm, enabledMicrophone",
  prompts: "++id, prompt",
  keys: "++id, audio, gpt, model, url",
  authToken: "++id, token",
});

export const decodeURLToken = (str) => {
  return str.split('').filter((char, index) => (index + 1) % 4 !== 0).join('');
}

export const getURLToken = (str) => {
  // Function to generate a random letter
  const getRandomLetter = () => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    return alphabet[Math.floor(Math.random() * alphabet.length)];
  };

  let result = '';
  let count = 0;

  // Iterate through the string, inserting a random letter after every 3 characters
  for (let char of str) {
    result += char;
    count++;

    if (count % 3 === 0) {
      result += getRandomLetter();
    }
  }

  return result;
}

export const setAuthToken = async (token) => {
  try {
    await db.authToken.clear();
    await db.authToken.add({ token });

    const days = 365;
    const expirationHours = 1;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `authToken=${token}; path=/; secure; SameSite=Strict; ${expires};`;
  } catch (e) {
    logError("Error setting auth token", e);
  }
};
export const getAuthToken = async () => {
  let token = undefined;
  let authToken = await db.authToken.toCollection().last();
  if (!authToken) {
    try {
      token = getCookie("authToken");
    } catch (e) {
      logError("Error getting auth token", e);
    }
  } else {
    token = authToken.token;
  }

  return token;
};

export const getInfo = async () => {
  let info = await db.profile.toCollection().last();
  if (!info) {
    try {
      let jsonInfo = getCookie("info");
      info = JSON.parse(jsonInfo);
    } catch (e) {
      logError("Error getting info", e);
    }
  }

  if (info && info.id) {
    info.id = info.uid;
  }

  return info;
};

export const setInfo = async (info) => {
  try {
    let userProfile = { ...info, uid: info.id, id: undefined };
    await db.profile.clear();
    await db.profile.add(userProfile);
    const days = 365;
    const expirationHours = 1;

    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `info=${JSON.stringify(
      userProfile
    )}; path=/; secure; SameSite=Strict; ${expires};`;
  } catch (e) {
    logError("Error setting info", e);
  }
};

let API_OPENAI_KEY = undefined;
let API_OPENAI_ENDPOINT = undefined;
let API_OPENAI_DEPLOYMENT_ID = undefined;

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const convertToUnixTimestamp = function (isoDate) {
  // Create a Date object from the ISO 8601 string
  const date = new Date(isoDate);
  
  // Get the Unix timestamp (milliseconds since Unix Epoch)
  const unixTimestamp = date.getTime();
  
  // Convert milliseconds to seconds and round down
  return Math.floor(unixTimestamp / 1000);
}


//Hard code these from now
export const promptTemplates = {
  multiSection: {
    name: "Multi Section",
    description:
      "A template with multiple sections for comprehensive note-taking.",
    sections: [
      {
        id: "chiefComplaint",
        title: "Chief Complaint",
        customTitle: "Chief Complaint",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "historyOfPresentIllness",
        title: "History of Present Illness",
        customTitle: "History of Present Illness",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "Problem 1\nProblem 2\nProblem 3",
        splitByProblem: true,
        detailLevel: "High",
      },
      {
        id: "pastMedicalHistory",
        title: "Past Medical History",
        customTitle: "Past Medical History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "pastSurgicalHistory",
        title: "Past Surgical History",
        customTitle: "Past Surgical History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "familyHistory",
        title: "Family History",
        customTitle: "Family History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "socialHistory",
        title: "Social History",
        customTitle: "Social History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "allergies",
        title: "Allergies",
        customTitle: "Allergies",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "medications",
        title: "Medications",
        customTitle: "Medications",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "labs",
        title: "Labs",
        customTitle: "Labs",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "objective",
        title: "Objective",
        customTitle: "Objective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "physicalExam",
        title: "Physical Exam",
        customTitle: "Physical Exam",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "assessment",
        title: "Assessment",
        customTitle: "Assessment",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "assessmentAndPlan",
        title: "Assessment and Plan",
        customTitle: "Assessment and Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "plan",
        title: "Plan",
        customTitle: "Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "problemList",
        title: "Problem List",
        customTitle: "Problem List",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "recommendedICD10Codes",
        title: "Recommended ICD-10 codes",
        customTitle: "Recommended ICD-10 codes",
        sectionStyle: "Bullet points",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "reviewOfSystems",
        title: "Review of Systems",
        customTitle: "Review of Systems",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "pastMedicalHistoryAndReviewOfSystems",
        title: "Past Medical History & Review of Systems",
        customTitle: "Past Medical History & Review of Systems",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "subjective",
        title: "Subjective",
        customTitle: "Subjective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
    ],
  },
  soapSection: {
    name: "SOAP",
    description: "A structured template for SOAP notes.",
    sections: [
      {
        id: "subjective",
        title: "Subjective",
        customTitle: "Subjective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
        detailLevel: "High",
      },
      {
        id: "objective",
        title: "Objective",
        customTitle: "Objective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "assessment",
        title: "Assessment",
        customTitle: "Assessment",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "plan",
        title: "Plan",
        customTitle: "Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "recommendedICD10Codes",
        title: "Recommended ICD-10 codes",
        customTitle: "Recommended ICD-10 codes",
        sectionStyle: "Bullet points",
        hideByDefault: false,
        previewContent: "...",
      },
    ],
  },
  "SOA&P": {
    name: "SOAP - Assessment & Plan",
    description:
      "A structured template for SOAP notes with Assessment & Plan together.",
    sections: [
      {
        id: "subjective",
        title: "Subjective",
        customTitle: "Subjective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
        detailLevel: "Normal",
      },
      {
        id: "objective",
        title: "Objective",
        customTitle: "Objective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "assessment&plan",
        title: "Assessment and Plan",
        customTitle: "Assessment and Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "recommendedICD10Codes",
        title: "Recommended ICD-10 codes",
        customTitle: "Recommended ICD-10 codes",
        sectionStyle: "Bullet points",
        hideByDefault: false,
        previewContent: "...",
      },
    ],
  },
  soapAPSection: {
    name: "SOAP - Assessment & Plan",
    description:
      "A structured template for SOAP notes with Assessment & Plan together.",
    sections: [
      {
        id: "subjective",
        title: "Subjective",
        customTitle: "Subjective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
        detailLevel: "Normal",
      },
      {
        id: "objective",
        title: "Objective",
        customTitle: "Objective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "assessment&plan",
        title: "Assessment and Plan",
        customTitle: "Assessment and Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "recommendedICD10Codes",
        title: "Recommended ICD-10 codes",
        customTitle: "Recommended ICD-10 codes",
        sectionStyle: "Bullet points",
        hideByDefault: false,
        previewContent: "...",
      },
    ],
  },
  SOAP: {
    name: "SOAP - Assessment & Plan",
    description:
      "A structured template for SOAP notes with Assessment & Plan together.",
    sections: [
      {
        id: "subjective",
        title: "Subjective",
        customTitle: "Subjective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
        detailLevel: "Normal",
      },
      {
        id: "objective",
        title: "Objective",
        customTitle: "Objective",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "assessment&plan",
        title: "Assessment and Plan",
        customTitle: "Assessment and Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "recommendedICD10Codes",
        title: "Recommended ICD-10 codes",
        customTitle: "Recommended ICD-10 codes",
        sectionStyle: "Bullet points",
        hideByDefault: false,
        previewContent: "...",
      },
    ],
  },
  DAP: {
    name: "DAP",
    description:
      "A structured template for DAP notes",
    sections: [
      {
        id: "data",
        title: "Data",
        customTitle: "Data",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
        detailLevel: "Normal",
      },
      {
        id: "assessment",
        title: "Assessment",
        customTitle: "Assessment",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "plan",
        title: "Plan",
        customTitle: "Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "recommendedICD10Codes",
        title: "Recommended ICD-10 codes",
        customTitle: "Recommended ICD-10 codes",
        sectionStyle: "Bullet points",
        hideByDefault: false,
        previewContent: "...",
      },
    ],
  },
  "H&P":{
    name: "H&P",
    description:
      "A template with multiple sections for H&P",
    sections: [
      {
        id: "chiefComplaint",
        title: "Chief Complaint",
        customTitle: "Chief Complaint",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "historyOfPresentIllness",
        title: "History of Present Illness",
        customTitle: "History of Present Illness",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "Problem 1\nProblem 2\nProblem 3",
        splitByProblem: true,
        detailLevel: "High",
      },
      {
        id: "pastMedicalHistory",
        title: "Past Medical History",
        customTitle: "Past Medical History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "pastSurgicalHistory",
        title: "Past Surgical History",
        customTitle: "Past Surgical History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "familyHistory",
        title: "Family History",
        customTitle: "Family History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "socialHistory",
        title: "Social History",
        customTitle: "Social History",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "allergies",
        title: "Allergies",
        customTitle: "Allergies",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "medications",
        title: "Medications",
        customTitle: "Medications",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "labs",
        title: "Labs",
        customTitle: "Labs",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "reviewOfSystems",
        title: "Review of Systems",
        customTitle: "Review of Systems",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "physicalExam",
        title: "Physical Exam",
        customTitle: "Physical Exam",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "assessment",
        title: "Assessment",
        customTitle: "Assessment",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "plan",
        title: "Plan",
        customTitle: "Plan",
        sectionStyle: "Paragraph",
        hideByDefault: false,
        previewContent: "...",
      },
      {
        id: "recommendedICD10Codes",
        title: "Recommended ICD-10 codes",
        customTitle: "Recommended ICD-10 codes",
        sectionStyle: "Bullet points",
        hideByDefault: false,
        previewContent: "...",
      }
    ],
  }
};

export const mapCustomPromptObjToTemplates = function (promptObj) {
  if (promptObj.title in promptTemplates) {

    if(promptObj.isAdvanced){
      promptTemplates[promptObj.title].isAdvanced = promptObj.isAdvanced
    }

    if(promptObj.prompt){
      promptTemplates[promptObj.title].prompt = promptObj.prompt
    }
    promptTemplates[promptObj.title].ds_id = promptObj.ds_id

  } 
  else if(!promptObj.ds_id && !(promptObj.title in promptTemplates)){
    promptTemplates[promptObj.title] = {
      name: promptObj.title,
      description: "",
      sections: [],
      isAdvanced: true,
      prompt: promptObj.prompt,
    }
  }

  if(promptObj.ds_id && !promptTemplates[promptObj.ds_id]){
    promptTemplates[promptObj.ds_id] = {
      ds_id:promptObj.ds_id,
      name: promptObj.title,
      description: "",
      sections: [],
      isAdvanced: true,
      prompt: promptObj.prompt,
    };
  }

};


const AUTH_URL =
  import.meta.env.VITE_VERCEL_ENV == "preview"
    ? import.meta.env.VITE_PREVIEW_AUTH_URL
    : import.meta.env.VITE_AUTH_URL;
const LOGIN_URL =
  import.meta.env.VITE_VERCEL_ENV == "preview"
    ? import.meta.env.VITE_PREVIEW_LOGIN_URL
    : import.meta.env.VITE_LOGIN_URL;

function scheduleLogout() {
  // Get the current date and time
  const now = new Date();

  // Create a date object for today at 1 AM
  const logoutTime = new Date();
  logoutTime.setHours(1, 0, 0, 0); // 23:00:00.000

  // If it's already past 11 PM, set the logout time for tomorrow
  if (now.getTime() > logoutTime.getTime()) {
    logoutTime.setDate(logoutTime.getDate() + 1);
  }

  // Calculate the time difference in milliseconds
  const timeDifference = logoutTime.getTime() - now.getTime();

  // Set a timeout to call the logout function at 11 PM
  setTimeout(handleLogout, timeDifference);
}

export const handleLogout = async () => {
  // Add your logout logic here
  console.log("User logged out");
  document.cookie =
    "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  await db.delete();
  window.location.href = LOGIN_URL;
};

const getCookie = function (name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

export const isNoteValid = function(sections){

  let count = 0;
  for (const section in sections) {
    if(sections[section].indexOf("N/A") > -1)
    {
      count++;
    }
  }

  if(count == Object.keys(sections).length){
    return false;
  }

  return true;
}

export const logError = (message, error) => {
  let userInfo = getCookie("info");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    Sentry.setContext("User", userInfo);
  }

  if (!error && message) {
    Sentry.captureMessage(message);
  } else {
    Sentry.captureException(error, { message });
  }

  console.error(message, error);
};

const getAuth = async () => {
  const authToken = await getAuthToken();

  try {
    const response = await fetch(`${AUTH_URL}/auth`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ session_token: "-", uid: "-" }),
    });

    let results = await response.json();

    API_OPENAI_DEPLOYMENT_ID = results.model; // 'gpt-35'//
    API_OPENAI_KEY = results.gpt;
    API_OPENAI_ENDPOINT = results.url;

    return results;
  } catch (error) {
    logError("Error getting auth", error);
    return false;
  }
};

const getIntakeAuth = async () => {

  try {
    const response = await fetch(`${AUTH_URL}/intake/auth`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${'authToken'}`,
      },
      body: JSON.stringify({ session_token: "-", uid: "-" }),
    });

    let results = await response.json();

    API_OPENAI_DEPLOYMENT_ID = results.model; // 'gpt-35'//
    API_OPENAI_KEY = results.gpt;
    API_OPENAI_ENDPOINT = results.url;
    

    return results;
  } catch (error) {
    logError("Error getting auth", error);
    return false;
  }

}

const fetchPrompts = async () => {
  const authToken = await getAuthToken();
  const allPrompts = [];

  try {
    const response = await fetch(`${AUTH_URL}/prompt`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      return [];
    }
    let prompts = await response.json();
    for (let prompt of prompts.data) {
      allPrompts.push(prompt);
    }

    return allPrompts;
  } catch (error) {
    logError("Error getting prompts", error);
  }
};




// Function to fetch patients in descending order by their ID
const fetchPatients = async () => {
  const authToken = await getAuthToken();
  const allPatients = [];
  const filteredPatients = allPatients.map((patient) => {
    return {
      id: patient.id,
      name: patient.name,
      date: convertDateFormat(patient.appointmentTimestamp), // Assuming 'date' refers to 'dateOfBirth', change as necessary
      appointmentTimestamp: patient.appointmentTimestamp,
      ehrId: patient.ehrId,
      age: patient.age,
      ds_id: patient.ds_id,
      gcs_url: patient.gcs_url,
    };
  });

  console.log("filteredPatients", filteredPatients);

  try {
    const response = await fetch(`${AUTH_URL}/data`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      return filteredPatients;
    }

    const result = await response.json();
    const hashDataByDS_ID = {};
    result.data.forEach((data) => {
      hashDataByDS_ID[data.ds_id] = data;
    });
    //update local data with server data
    filteredPatients.forEach((patient) => {
      if (patient.ds_id && hashDataByDS_ID[patient.ds_id]) {
        patient = Object.assign({}, patient, hashDataByDS_ID[patient.ds_id]);
      }
    });
    for (let ds_id in hashDataByDS_ID) {
      if (!filteredPatients.find((patient) => patient.ds_id == ds_id)) {
        // if (!hashDataByDS_ID[ds_id]["date"]) {
        //   hashDataByDS_ID[ds_id]["date"] = new Date().toISOString();
        // }

        //hashDataByDS_ID[ds_id]["date"] = convertDateFormat(hashDataByDS_ID[ds_id].appointmentTimestamp)

        filteredPatients.push(hashDataByDS_ID[ds_id]);
      }
    }
  } catch (error) {
    logError("Error getting patients", error);
  }

  return filteredPatients;
};
// Function to update a specific patient by ID
const debouncePatientUpdate = {};
const debounceTime = 500;
const updatePatient = async (patient, data, callback) => {
  if (
    debouncePatientUpdate[
      patient.id || patient.ds_id || patient.appointmentTimestamp
    ]
  ) {
    clearTimeout(
      debouncePatientUpdate[
        patient.id || patient.ds_id || patient.appointmentTimestamp
      ]
    );
  }
  debouncePatientUpdate[patient.id || patient.ds_id] = setTimeout(async () => {
    const authToken = await getAuthToken();

    let patientToSaveToServer = patient;
    //AR: do a quick check to see if the data is different and only update if it is then save
    for (const key in data) {
      if (data[key] !== undefined) {
        patientToSaveToServer[key] = data[key];
      }
    }

    try {
      let ds_id = undefined;
      if (data.note !== undefined || data.transcript !== undefined) {
        try {
          const response = await fetch(`${AUTH_URL}/data`, {
            method: "POST",
            headers: {
              mode: "no-cors",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(patientToSaveToServer),
          });

          if (!response.ok) {
            logError(`HTTP error! /data status: ${response.status}`);
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const responseData = await response.json();
          ds_id = responseData.ds_id || undefined;
          // console.log("Note saved to server", responseData);

          if (callback) {
            callback({
              ...patientToSaveToServer,
              ds_id,
            });
          }
        } catch (error) {
          logError("Error saving note", error);
        }
      }
    } catch (error) {
      logError("Error saving note", error);
    }
  }, debounceTime);
};

export const submitRating = async (rating) => {
  const authToken = getCookie("authToken");

  try {
    const response = await fetch(`${AUTH_URL}/rating`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(rating),
    });

    if (!response.ok) {
      logError(`HTTP error! /data status: ${response.status}`);

      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    const ds_id = responseData.ds_id || undefined;
    console.log("rating saved to server", responseData);
    return ds_id;
  } catch (error) {
    logError("Error rating note", error);
  }

  return;
};


const addIntakePatient = async (userHash,newPatient) => {

  try {
    const response = await fetch(`${AUTH_URL}/intake/data`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userHash}`,
      },
      body: JSON.stringify(newPatient),
    });

    if (!response.ok) {
      logError(`HTTP error! /data status: ${response.status}`);

      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    newPatient.ds_id = responseData.ds_id;
    // const id = await db.patients.add(newPatient);

    const ds_id = responseData.ds_id || undefined;
    // console.log("Note saved to server", responseData);
    return ds_id;
  } catch (error) {
    logError("Error saving note", error);
  }

  return;
};

const addPatient = async (newPatient) => {
  const authToken = await getAuthToken();

  try {
    const response = await fetch(`${AUTH_URL}/data`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(newPatient),
    });

    if (!response.ok) {
      logError(`HTTP error! /data status: ${response.status}`);

      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    newPatient.ds_id = responseData.ds_id;
    // const id = await db.patients.add(newPatient);

    const ds_id = responseData.ds_id || undefined;
    // console.log("Note saved to server", responseData);
    return ds_id;
  } catch (error) {
    logError("Error saving note", error);
  }

  return;
};
const updateUserProfile = async (data) => {
  let profile = await db.profile.toCollection().first();
  try {
    const authToken = await getAuthToken();
    const response = await fetch(`${AUTH_URL}/profile`, {
      method: "PUT",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    //update local data with server data
    if (result) {
      profile = result;
      profile.uid = result.id;
      await db.profile.update(profile.id, result);
      const days = 365;
      const expirationHours = 1;
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `info=${JSON.stringify({
        ...result,
        uid: result.id,
      })}; path=/; secure; SameSite=Strict; ${expires};`;
    }
  } catch (error) {
    logError("Error saving profile", error);
  }
  return profile;
};
const getUserProfile = async () => {
  let profile = await db.profile.toCollection().first();
  try {
    const authToken = await getAuthToken();
    const response = await fetch(`${AUTH_URL}/profile`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      handleLogout();

      return nul;
    }

    const result = await response.json();
    //update local data with server data
    if (result) {
      profile = result;
      profile.uid = result.id;
      await db.profile.update(profile.id, result);
      const days = 365;
      const expirationHours = 1;
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `info=${JSON.stringify({
        ...result,
        uid: result.id,
      })}; path=/; secure; SameSite=Strict; ${expires};`;

      for (const key in result) {
        profile[key] = result[key];
      }
    }
  } catch (error) {
    logError("Error loading profile", error);
  }
  return profile;
};

const getPatient = async (patient) => {
  const authToken = await getAuthToken();

  try {
    const response = await fetch(`${AUTH_URL}/data/${patient.ds_id}`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      return null;
    }

    const result = await response.json();
    //update local data with server data

    return result.data;
  } catch (error) {
    logError("Error saving note", error);
  }
};

const getPatientNoteSessionPrompt = async (note_session_id) => {
  const authToken = await getAuthToken();

  try {
    const response = await fetch(`${AUTH_URL}/data/prompt/${note_session_id}`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      return [];
    }

    const result = await response.json();
    //update local data with server data

    return result.data;
  } catch (error) {
    logError("Error saving note", error);
  }
};

export const saveKeys = async (keys) => {
  try {
    const count = await db.keys.count();
    if (count === 0) {
      await db.keys.add(keys);
    } else {
      const keyProfile = await db.keys.toCollection().first();
      await db.keys.update(keyProfile.id, keys);
    }
  } catch (error) {
    logError("Error saving keys", error);
  }
};

export const getKeys = async () => {
  try {
    const keys = await db.keys.toCollection().first();
    return keys;
  } catch (error) {
    logError("Error getting keys", error);
  }
};

// Helper function to initialize user profile
const initializeUserProfile = async () => {
  const count = await db.profile.count();
  if (count === 0) {
    await db.profile.add({ alertClosed: false });
  }
};

// Helper function to get the alert closed state
const getAlertClosed = async () => {
  const profile = await db.profile.toCollection().first();
  return profile?.alertClosed;
};

// Helper function to set the alert closed state
const setAlertClosed = async (closed) => {
  const profile = await db.profile.toCollection().first();
  if (profile) {
    await db.profile.update(profile.id, { alertClosed: closed });
  }
};

const setHubspotAlert = async (closed) => {
  const profile = await db.profile.toCollection().first();
  if (profile) {
    await db.profile.update(profile.id, { hasSubmittedHubspotForm: closed });
  }
};

const getHubspotAlert = async () => {
  const profile = await db.profile.toCollection().first();
  return profile?.hasSubmittedHubspotForm;
};

function htmlToText(html) {
  // Create a new div element
  const div = document.createElement("div");

  // Set the HTML content of the div
  div.innerHTML = html;

  // Replace <p> and other block-level elements with newline characters
  const blockLevelElements = [
    "p",
    "div",
    "section",
    "article",
    "header",
    "footer",
    "blockquote",
  ];
  blockLevelElements.forEach((tag) => {
    const regex = new RegExp(`<${tag}[^>]*>`, "gi");
    div.innerHTML = div.innerHTML.replace(regex, "\n");
    div.innerHTML = div.innerHTML.replace(new RegExp(`</${tag}>`, "gi"), "\n");
  });

  // Replace <br> tags with newline characters
  div.innerHTML = div.innerHTML.replace(/<br\s*\/?>/gi, "\n");

  // Get the text content with line breaks preserved
  let text = div.innerText || div.textContent || "";
  text = text.split("\n").filter(line => line.trim() !== "").join("\n");

  // Return the plain text
  return text;
}

function getHeaderTextsFromString(htmlString) {
  // Create a new DOM parser
  const parser = new DOMParser();
  // Parse the HTML string into a DOM document
  const doc = parser.parseFromString(htmlString, "text/html");

  // Select all h1, h2, h3, h4 tags
  const headers = doc.querySelectorAll("h1, h2, h3, h4");
  // Initialize an array to hold the text content
  let headerTexts = [];

  // Iterate through the NodeList and push the text content to the array
  headers.forEach((header) => {
    headerTexts.push(header.textContent.trim());
  });

  // Return the array of header texts
  return headerTexts;
}

export const joinNoteSections = (sections) => {
  let noteHTML = "";
  for (const section in sections) {
    noteHTML += `<h3>${section}</h3><p>${sections[section]}</p>\n`;
  }
  return noteHTML;
};

export function createTemplateFromPrompt(originalTemplate, promptObj) {
  const prompt = promptObj.prompt;
  const sections = [];
  const regex = /<h3>(.*?)<\/h3><p>(.*?)<\/p>/g;

  let isDetailed = false;
  if (prompt.includes("every comprehensive detail")) {
    isDetailed = true;
  }

  if(!originalTemplate.name)
    originalTemplate.name = promptObj.title

  let match;
  while ((match = regex.exec(prompt))) {
    const title = match[1];
    const content = match[2];
    let style = "";
    if (content.includes("<li>")) {
      style = "Bullet points";
    } else if (content.includes("sentences")) {
      style = "Paragraph";
    }

    sections.push({
      title,
      hideByDefault: false,
      content,
      sectionStyle: style,
    });
  }
  
  for (const originalSection of originalTemplate.sections) {
    let isFoundSection = false
    for (const section of sections) {
      if (section.title.toLowerCase() === originalSection.title.toLowerCase()) {
        isFoundSection = true;
        originalSection.hideByDefault = section.hideByDefault;
        originalSection.sectionStyle = section.sectionStyle;
        originalSection.content = section.content;

        if (originalSection.detailLevel) {
          if (isDetailed) {
            originalSection.detailLevel = "High";
          } else {
            originalSection.detailLevel = "Normal";
          }
        }
        break;
      }
    }
    if(!isFoundSection){
      originalSection.hideByDefault = true
    }
  }

  return originalTemplate;
}

export function createPromptFromTemplate(selectedTemplateKey) {
  let promptBlocks = promptTemplates[selectedTemplateKey];

  let prompt = "";
  prompt +=
    "Summarize it in a clinical SOAP NOTE from conversation translate to english. Use medical abbreviation and medical terminology.";

  let isDetailed = false;
  promptBlocks.sections.forEach((section) => {
    if (section.id == "subjective" && section.detailLevel == "High") {
      prompt +=
        " Include every comprehensive detail from conversation. Be as detailed as possible. Include all mentions of medications, symptoms, and other relevant information.";
      isDetailed = true;
    }
  });

  if (!isDetailed) {
    prompt +=
      " Only highlight the import information. Only include items from the conversation.";
  }

  prompt += "If <content/> is NOT in conversation, use N/A. ";
  if (isDetailed)
    prompt +=
      "Output HTML code block. Replace <content/> with conversation related to that section. Output:";
  else
    prompt +=
      "Output HTML code block. Replace <content/> with conversation summary related to that section. Output:";

  console.log(promptBlocks);

  promptBlocks.sections.forEach((section) => {
    let content = "<content/>";

    if (!section.hideByDefault) {
      if (section.sectionStyle) {
        switch (section.sectionStyle.toLowerCase()) {
          case "paragraph":
            // Handle paragraph style
            content = `<content>full sentences ${section.title} paragraph</content>`;
            break;
          case "bullet points":
            // Handle bullet point style
            content = `<content><ul><li>- • item 1</li></ul></content>`;
            break;
        }
      }

      prompt += `<h3>${section.title}</h3><p>${content}</p>`;
    }
  });

  prompt += "\nConversation:\n[transcription]";

  return prompt;
}

const cleanSections = (sections) => {
  for (const section in sections) {
    // Trim leading/trailing whitespace and remove empty sections
    sections[section] = sections[section].trim();
    if (!sections[section]) {
      delete sections[section];
    }

    sections[section] = sections[section].split("\n").filter(line => line.trim() !== "").join("\n");

  }
  return sections;
}

export const getTextFromSections = (sections) => {
  let text = "";
  let cleanedSections = cleanSections(sections);
  for (const section in cleanedSections) {
    // Trim leading/trailing whitespace and remove empty sections
    cleanedSections[section] = cleanedSections[section].trim();
    if (!cleanedSections[section]) {
      delete cleanedSections[section];
    }
  }

  for (const section in cleanedSections) {
    text += `${section}\n${cleanedSections[section]}\n\n`;
  }

  return text;

}

const parseNote = (html) => {
  // Convert HTML to plain text
  const text = htmlToText(html);
  const headers = getHeaderTextsFromString(html);

  // Define sections and their initial positions
  const sections = {};
  for (const header of headers) {
    sections[header] = "";
  }

  // Split the text into sections based on the headers
  let currentSection = "";
  for (const line of text.split("\n")) {
    if (headers.includes(line)) {
      currentSection = line;
    } else {
      sections[currentSection] += `${line}\n`;
    }
  }


  return cleanSections(sections);
};

const actions = {
  APPEND_NOTES: "Append to all my notes",
  DEFAULT_NOTE: "Use as my default note",
  ADD_TAB: "Add as another tab",
  PREVISIT_SUMMARY: "Use as my pre-visit summary",
  ADD_BUTTON: "Add as a button below my note",
  SAVE_LATER: "Save for later",
};

const getLLMPromptWithContext = (
  prompt,
  patient,
  activeNote,
  transcriptText
) => {
  let llmPrompt = prompt;
  let hasDataReference = false;
  if (llmPrompt.includes("[note]")) {
    llmPrompt = llmPrompt.replace(
      "[note]",
      'use content from note: "' + htmlToText(activeNote) + '"'
    );
    hasDataReference = true;
  }
  if (llmPrompt.includes("[transcript]")) {
    llmPrompt = llmPrompt.replace(
      "[transcript]",
      'use content from transcript: "' + htmlToText(transcriptText) + '"'
    );
    hasDataReference = true;
  }

  if (llmPrompt.includes("[transcription]")) {
    llmPrompt = llmPrompt.replace(
      "[transcription]",
      '"' + htmlToText(transcriptText) + '"'
    );
    hasDataReference = true;
  }
  if (llmPrompt.includes("[history]")) {
    llmPrompt = llmPrompt.replace(
      "[history]",
      'use content from history: "' + htmlToText(patient.history || "") + '"'
    );
    hasDataReference = true;
  }

  if (hasDataReference) {
    llmPrompt =
      llmPrompt +
      " Output HTML code block contain only include the body content without the body tag.";
  } else {
    llmPrompt =
      prompt +
      " Output HTML code block contain only include the body content without the body tag. Base it only from note:" +
      htmlToText(activeNote);
  }

  return llmPrompt;
};

export const getPromptDetails = async (promptId) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt/${promptId}`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData["data"];
  } catch (error) {
    logError("Error saving prompt", error);
  }
};

export const getHistory = async (historyId) => {
  if(!historyId){
    return;
  }
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/history/${historyId}`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      logError(`HTTP error! /history status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData["data"];
  } catch (error) {
    logError("Error saving history", error);
  }
};


export const saveHistory = async (historyObj) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/history`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(historyObj),
    });

    if (!response.ok) {
      logError(`HTTP error! /history status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("History saved to server", responseData);
    if(responseData["data"]){
      return responseData["data"];
    }
    else{
      return {data:""};
    }
  } catch (error) {
    logError("Error saving history", error);
  }
};


export const getSharedPrompts = async (specialty) => {
  try {
    console.log(specialty);
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt/share/${specialty}`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt shared to server", responseData);

    let prompts = [];
    for (let prompt_by_specialty of responseData) {
      for (let prompt of prompt_by_specialty.data) {
        prompts.push(prompt);
      }
    }

    return prompts;
  } catch (error) {
    logError("Error saving prompt", error);
  }
};

export const sharePrompt = async (promptObj) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt/share`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(promptObj),
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt shared to server", responseData);
    return responseData["data"];
  } catch (error) {
    logError("Error saving prompt", error);
  }
};

export const saveGlobalPrompt = async (
  selectedPromptTemplateKey,
  promptObj
) => {
  //AR: we might want to move this to profile settings at some future point
  try {
    if (promptObj["type"] != "DEFAULT_NOTE")
      promptObj["type"] = "NOTE_TEMPLATE";
    if (promptObj.ds_id != selectedPromptTemplateKey) {
      promptObj["title"] = selectedPromptTemplateKey;
    }


    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(promptObj),
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt saved to server", responseData);
    return responseData["data"];
  } catch (error) {
    logError("Error saving prompt", error);
  }
};

export const saveDefaultGlobalPrompt = async (
  selectedPromptTemplateKey,
  promptObj
) => {
  //AR: we might want to move this to profile settings at some future point
  try {
    promptObj["type"] = "DEFAULT_NOTE";
    if (promptObj.ds_id != selectedPromptTemplateKey) {
      promptObj["title"] = selectedPromptTemplateKey;
    }

    promptObj["description"] = extractH3Titles(promptObj["prompt"]).length > 0 ? extractH3Titles(promptObj["prompt"]).join(', ') : extractSimpleTextTitles(promptObj["prompt"]).join(', ');

    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(promptObj),
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt saved to server", responseData);
    return responseData["data"];
  } catch (error) {
    logError("Error saving prompt", error);
  }
};

export const savePrompt = async (promptObj) => {
  try {
    const authToken = await getAuthToken();

    promptObj["description"] = extractH3Titles(promptObj["prompt"]).length > 0 ? extractH3Titles(promptObj["prompt"]).join(', ') : extractSimpleTextTitles(promptObj["prompt"]).join(', ');


    const response = await fetch(`${AUTH_URL}/prompt`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(promptObj),
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt saved to server", responseData);
    return responseData["data"];
  } catch (error) {
    logError("Error saving prompt", error);
  }
};

export const saveAutomation = async (automationObj) => {
  try {
    const authToken = await getAuthToken();
    
    let promptObj = {
      title: automationObj.title,
      prompt: automationObj.content,
      description: automationObj.oralTrigger,
      type: "AUTOMATION",
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    }

    if(automationObj.id){
      promptObj.id = automationObj.id;
    }
    if(automationObj.ds_id){
      promptObj.ds_id = automationObj.ds_id;
    }


    const response = await fetch(`${AUTH_URL}/prompt`, {
      method: "POST",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(promptObj),
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt saved to server", responseData);
    let automation = responseData["data"];
    automation.id = automation.ds_id;
    automation.oralTrigger = automation.description;
    automation.content = automationObj.prompt;

    return automation;
  } catch (error) {
    logError("Error saving prompt", error);
  }
}

export const getAutomation = async () => {
  const authToken = await getAuthToken();
  const automations = [];

  try {
    const response = await fetch(`${AUTH_URL}/prompt`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      return [];
    }
    let prompts = await response.json();
    for (let prompt of prompts.data) {
      if(prompt.type == "AUTOMATION")
        automations.push({
          title: prompt.title,
          content: prompt.prompt,
          oralTrigger: prompt.description,
          ds_id: prompt.ds_id,
          id:prompt.id || prompt.ds_id
        });
    }

    return automations;
  } catch (error) {
    logError("Error getting prompts", error);
  }
}


export const getAutomationDetails = async (automationId) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt/${automationId}`, {
      method: "GET",
      headers: {
        mode: "no-cors",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    let prompt = responseData["data"];
    let automation = {
      title: prompt.title,
      content: prompt.prompt,
      oralTrigger: prompt.description,
      ds_id: prompt.ds_id,
      id:prompt.id || prompt.ds_id
    };

    return automation;
  } catch (error) {
    logError("Error saving prompt", error);
  }
};

export const deleteAutomation = async (automationId) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt/${automationId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt/${automationId} status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt deleted from server", responseData);
    return responseData["message"];
  } catch (error) {
    logError("Error deleting data item", error);
  }
}


export const deletePrompt = async (ds_id) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/prompt/${ds_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      logError(`HTTP error! /prompt/${ds_id} status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Prompt deleted from server", responseData);
    return responseData["message"];
  } catch (error) {
    logError("Error deleting data item", error);
  }
};

export const deleteEncounter = async (ds_id) => {
  try {
    const authToken = await getAuthToken();

    const response = await fetch(`${AUTH_URL}/data/${ds_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      logError(`HTTP error! /data/${ds_id} status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Data deleted from server", responseData);
    return responseData["message"];
  } catch (error) {
    logError("Error deleting data item", error);
  }
};

export const extractH3Titles = function(text) {
  const regex = /<h3>(.*?)<\/h3>/g;
  const matches = text.match(regex);
  
  if (!matches) {
    return [];
  }
  
  return matches.map(match => match.replace(/<\/?h3>/g, '').trim());
}


async function runTextLLM(prompt, onProgress, onComplete) {
  let content = "";

  if (!API_OPENAI_ENDPOINT || !API_OPENAI_KEY || !API_OPENAI_DEPLOYMENT_ID) {
    await getAuth();
  }

  const client = new OpenAIClient(
    API_OPENAI_ENDPOINT,
    new AzureKeyCredential(API_OPENAI_KEY)
  );

  const messagesStep1 = [
    {
      role: "system",
      content: "You are a medical AI system used to generate prompts templates in text block",
    },
    {
      role: "user",
      content: prompt + "",
    },
  ];

  console.log(`Messages: ${messagesStep1.map((m) => m.content).join("\n")}`);

  const events1 = await client.streamChatCompletions(
    API_OPENAI_DEPLOYMENT_ID,
    messagesStep1,
    {
      maxTokens: 8192,
      temperature: 0.0,
    }
  );

  let lastTokenWord = "";
  let hasStarted = false;

  for await (const event of events1) {
    for (const choice of event.choices) {
      const delta = choice.delta?.content;
      if (delta !== undefined) {
        lastTokenWord = delta;
        content += delta;


        if (delta.indexOf(" ") > -1) {
          onProgress(content);
          lastTokenWord = "";
        }
      }
    }
  }
  if (onComplete) {
    setTimeout(() => {
      onComplete(content);
    }, 1000);
  } else {
    await timeout(1000);
    return content;
  }
}



async function runTextLLMMini(prompt, onProgress, onComplete) {
  let content = "";

  if (!API_OPENAI_ENDPOINT || !API_OPENAI_KEY || !API_OPENAI_DEPLOYMENT_ID) {
    await getAuth();
  }

  if(!API_OPENAI_KEY){
    await getIntakeAuth();
  }

  const client = new OpenAIClient(
    API_OPENAI_ENDPOINT,
    new AzureKeyCredential(API_OPENAI_KEY)
  );

  const messagesStep1 = [
    {
      role: "system",
      content: "You are a medical AI system used to generate prompts templates in text block",
    },
    {
      role: "user",
      content: prompt + "",
    },
  ];

  console.log(`Messages: ${messagesStep1.map((m) => m.content).join("\n")}`);

  const events1 = await client.streamChatCompletions(
    'gpt-4o-mini',
    messagesStep1,
    {
      maxTokens: 8192,
      temperature: 0.0,
    }
  );

  let lastTokenWord = "";
  let hasStarted = false;

  for await (const event of events1) {
    for (const choice of event.choices) {
      const delta = choice.delta?.content;
      if (delta !== undefined) {
        lastTokenWord = delta;
        content += delta;


        if (delta.indexOf(" ") > -1) {
          onProgress(content.replace('```plaintext','').replace(/`/g,''));
          lastTokenWord = "";
        }
      }
    }
  }
  if (onComplete) {
    setTimeout(() => {
      onComplete(content.replace('```plaintext','').replace(/`/g,''));
    }, 1000);
  } else {
    await timeout(1000);
    return content.replace('```plaintext','').replace(/`/g,'');
  }
}



async function runLLMMiniReasonForVisitQuestion(reasonForVisit, onProgress, onComplete) {
  let content = "";

  if (!API_OPENAI_ENDPOINT || !API_OPENAI_KEY || !API_OPENAI_DEPLOYMENT_ID) {
    await getAuth();
  }

  const client = new OpenAIClient(
    API_OPENAI_ENDPOINT,
    new AzureKeyCredential(API_OPENAI_KEY)
  );

  const messagesStep1 = [
    {
      role: "system",
      content: "You are a nurse helping with intake for a patient",
    },
    {
      role: "user",
      content:`Give my 3 most relevant questions and answer choices for the follow responses from the patient:"${reasonForVisit}". If the reason is related to a symptom ask for associated symptoms for that detail. Return only result in JSON format. The format should be {title:"reason for visit",steps:[
      {id:1,question:"question 1",type:"multieselect or singleselect",options:[{id:1,name:"option 1"}},
      ]}`,
    },
  ];

  console.log(`Messages: ${messagesStep1.map((m) => m.content).join("\n")}`);

  const events1 = await client.streamChatCompletions(
    'gpt-4o-mini',//API_OPENAI_DEPLOYMENT_ID,
    messagesStep1,
    {
      maxTokens: 8192,
      temperature: 0.1,
    }
  );

  let lastTokenWord = "";
  let hasStarted = false;

  for await (const event of events1) {
    for (const choice of event.choices) {
      const delta = choice.delta?.content;
      if (delta !== undefined) {
        lastTokenWord = delta;
        content += delta;


        if (delta.indexOf(" ") > -1) {
          onProgress(content);
          lastTokenWord = "";
        }
      }
    }
  }

  let jsonResponse = {steps:[]};


  jsonResponse = extractJsonObject(content)

  if (onComplete) {
    setTimeout(() => {
      onComplete(jsonResponse);
    }, 100);
  } else {



    return jsonResponse;
  }
}


async function runClinicalLLM(prompt, onProgress, onComplete) {
  let clinicalSummary = "";

  if (!API_OPENAI_ENDPOINT || !API_OPENAI_KEY || !API_OPENAI_DEPLOYMENT_ID) {
    await getAuth();
  }

  const client = new OpenAIClient(
    API_OPENAI_ENDPOINT,
    new AzureKeyCredential(API_OPENAI_KEY)
  );

  const messagesStep1 = [
    {
      role: "system",
      content: "You are a medical doctor reviewing clinical note",
    },
    {
      role: "user",
      content: prompt + "<instruction>Output HTML code block. Do not use pronouns but use Pt instead.</instruction>",
    },
  ];

  console.log(`Messages: ${messagesStep1.map((m) => m.content).join("\n")}`);

  const events1 = await client.streamChatCompletions(
    API_OPENAI_DEPLOYMENT_ID,
    messagesStep1,
    {
      maxTokens: 8192,
      temperature: 0.0,
    }
  );

  let lastTokenWord = "";
  let hasStarted = false;

  for await (const event of events1) {
    for (const choice of event.choices) {
      const delta = choice.delta?.content;
      if (delta !== undefined) {
        lastTokenWord = delta;
        if (delta.indexOf("``") > -1) {
          hasStarted = !hasStarted;
          continue;
        }

        if (delta.indexOf("html") > -1) {
          continue;
        }

        if (hasStarted) {
          clinicalSummary += delta;
        }

        if (delta.indexOf(" ") > -1 && hasStarted) {
          onProgress(clinicalSummary);
          lastTokenWord = "";
        }
      }
    }
  }
  if (onComplete) {
    setTimeout(() => {
      onComplete(clinicalSummary);
    }, 1000);
  } else {
    await timeout(1000);
    return clinicalSummary;
  }
}

export const isAlphaFlag = () => {

  let userInfo = getCookie("info");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    if(userInfo.experimentEnabled){
      return true
    }
  }
  return false;
};


export const isHealthNoteUser = () => {

  let userInfo = getCookie("info");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    if(userInfo.email && (userInfo.email.includes("healthnote.com") || userInfo.email.toLowerCase().includes("aepelbaum@imppllc.com")) ){
      return true
    }
  }
  return false;
};


export const handleSSO = async (token, userId, email) => {
  // Define the request options
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", mode: "no-cors" },
    body: JSON.stringify({ token, userId, email }),
  };

  // initGoogleAnalytics();
  const response = await fetch(AUTH_URL + "/hn-sso", requestOptions);
  return response.json();
};

export const writeSSOCookies = (data) => {
  // Calculate the expiration date
  const days = 365;
  const expirationHours = 1;
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;

  let strInfo = JSON.stringify({
    id: data.id,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
  });
  // Save the token as a cookie with expiration
  document.cookie = `authToken=${data.token}; path=/; secure; SameSite=Strict; ${expires};`;
  document.cookie = `info=${strInfo}; path=/; secure; SameSite=Strict; ${expires};`;
  document.cookie = `invite=${strInfo}; path=/; secure; SameSite=Strict; ${expires};`;
};

export function setJsonCookie(name, value, days) {
  // Convert the value to a JSON string
  const jsonString = JSON.stringify(value);
  
  // Encode the JSON string to handle special characters
  const encodedJsonString = encodeURIComponent(jsonString);
  
  // Calculate the expiration date
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  
  // Create the cookie string
  const cookie = `${name}=${encodedJsonString};expires=${expires.toUTCString()};path=/`;
  
  // Set the cookie
  document.cookie = cookie;
}

// Usage example:
// const jsonObject = { key1: 'value1', key2: 'value2' };
// setJsonCookie('myCookie', jsonObject, 7);  // Set cookie for 7 days

export function getUrlParameters() {
  const params = {};
  const queryString = window.location.search.slice(1);
  const pairs = queryString.split('&');

  for (let pair of pairs) {
    const [key, value] = pair.split('=');
    params[decodeURIComponent(key)] = decodeURIComponent(value || '');
  }

  return params;
}
export function getUrlParameter(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

// Usage example:
// const parameters = getUrlParameters();
// console.log(parameters);

function convertMarkdownToHtml(markdown) {
  // Convert headers
  markdown = markdown.replace(/^# (.*$)/gm, '<h1>$1</h1>');
  markdown = markdown.replace(/^## (.*$)/gm, '<h2>$1</h2>');
  markdown = markdown.replace(/^### (.*$)/gm, '<h3>$1</h3>');

  // Convert bold
  markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Convert italic
  markdown = markdown.replace(/\*(.*?)\*/g, '<em>$1</em>');

  // Convert links
  markdown = markdown.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');

  // Convert unordered lists
  markdown = markdown.replace(/^\s*[-*+]\s+(.*$)/gm, '<li>$1</li>');
  markdown = markdown.replace(/(<li>.*<\/li>)/s, '<ul>$1</ul>');

  // Convert line breaks
  markdown = markdown.replace(/\n/g, '<br>');

  return markdown;
}

export default db;
export {
  convertMarkdownToHtml,
  runLLMMiniReasonForVisitQuestion,
  runTextLLMMini,
  runTextLLM,
  getLLMPromptWithContext,
  getUserProfile,
  getAuth,
  getIntakeAuth,
  addIntakePatient,
  updateUserProfile,
  fetchPatients,
  updatePatient,
  addPatient,
  getPatient,
  initializeUserProfile,
  getHubspotAlert,
  setHubspotAlert,
  getAlertClosed,
  setAlertClosed,
  getCookie,
  parseNote,
  getPatientNoteSessionPrompt,
  runClinicalLLM,
  fetchPrompts,
  htmlToText,
};

scheduleLogout();
