//https://developers.deepgram.com/docs/lower-level-websockets

//https://stackoverflow.com/questions/74263433/how-to-stream-client-side-webrtc-audio-to-google-speech-to-text-server
//https://github.com/untilhamza/Real-time-transcription-with-Google-speech-to-text-API/tree/main?tab=readme-ov-file

// JavaScript function to connect to Deepgram's WebSocket API for real-time transcription
import {logError, getAuthToken, getIntakeAuth} from './db'
import {
    Analytics,
    StandardAnalyticsService,
  } from "./analytics"; // Adjust this import path as necessary



  const API_BASE_URL =
  import.meta.env.VITE_VERCEL_ENV == "preview"
    ? import.meta.env.VITE_PREVIEW_AUTH_URL
    : import.meta.env.VITE_AUTH_URL;

  let API_DEEPGRAM_KEY = undefined;

  export function connectToDeepgram(apiKey, language, dictation, onConnect, onClose, onProgress, onTranscribe) {
    const analyticsService = StandardAnalyticsService.shared;
    if(apiKey){
        API_DEEPGRAM_KEY = apiKey;
    }

    let url = 'wss://api.deepgram.com/v1/listen?model=nova-2-medical&interim_results=true&smart_format=true' + (dictation ? '&dictation=true&punctuate=true' : '');
    if (language && language != 'en') {
        url = `wss://api.deepgram.com/v1/listen?model=nova-2&language=${language}&interim_results=true&smart_format=true`+ (dictation ? '&dictation=true&punctuate=true' : '');
    }

    if(dictation){
        url = `wss://api.deepgram.com/v1/listen?model=nova-2&language=${language}&interim_results=true&smart_format=true`+ (dictation ? '&dictation=true&punctuate=true' : '');
    }

    if(language == 'enes'){
        url = `wss://api.deepgram.com/v1/listen?model=nova-2&language=multi&interim_results=true&smart_format=true`;
    }

    // Create a WebSocket connection
    const ws = new WebSocket(url, ['token', apiKey]);

    // Event handler for when the connection opens
    ws.onopen = () => {
        //console.log('Connected to Deepgram');
        onConnect(ws);
    };

    
    // Event handler for incoming messages from Deepgram
    ws.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === 'Results') {
            if (onProgress)
                onProgress(data.channel.alternatives[0].transcript)
            if (data.is_final) {
                onTranscribe(data.channel.alternatives[0].transcript)
            }
        }

        return false;
    };

    // Event handler for when the WebSocket connection closes
    ws.onclose = () => {
        // console.log('Disconnected from Deepgram');
        onClose();
    };

    ws.finish = () => {
        ws.send(JSON.stringify({ type: "CloseStream" }));
        ws.close()
        clearInterval(ws.keepAlive);
    }

    // Event handler for errors
    ws.onerror = (error) => {
        logError("Deepgram WebSocket Error", error)
        analyticsService.trackEvent(
            Analytics.Category.ERROR,
            Analytics.Action.CREATED,
            Analytics.Category.RECORDING,
            "",
            "Deepgram WebSocket Error");
    };

    ws.close = () => {
        ws.send(JSON.stringify({ type: "CloseStream" }));
    }

    ws.keepAlive = setInterval(() => {
        if(ws.readyState === WebSocket.CLOSED){

        }
        else if(ws.readyState === WebSocket.OPEN)
        {
            ws.send(JSON.stringify({ type: "KeepAlive" }));
        }
    },10000)

    return ws;
}
export function uploadAudio(type, token, blob) {
    return fetch('https://api.deepgram.com/v1/listen?model=nova-2-medical&smart_format=true', {
        method: 'POST',
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': type // Ensure this matches the type of blob you're sending; change if needed
        },
        body: blob // Send the blob directly as the body of the request
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .catch(error => {
        logError("Deepgram upload file Error", error)

        throw error; // Rethrow after logging to handle it further up the chain
    });
}




export async function textToSpeech(text) {
    try {
        const token = ""//await getAuthToken();
        
        const response = await fetch(`${API_BASE_URL}/tts`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to get audio from server');
        }

        const data = await response.json();
        
        // Convert base64 to Blob
        const binaryString = atob(data.audio);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        
        return new Blob([bytes], { type: 'audio/wav' });
    } catch (error) {
        console.error("Error in text-to-speech:", error);
        throw error;
    }
}


export default connectToDeepgram