import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Select from 'react-select';
import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

import {
  getAuthToken,
  getURLToken,
  getUserProfile,
  setAuthToken,
} from './db';

const specialties = [
  'Addiction Medicine',
  'Adolescent and Young Adult Medicine',
  'Allergy',
  'Anesthesiology',
  'Behavioral Health',
  'Cardiology',
  'Chiropractic',
  'Dentistry',
  'Dermatology',
  'Diabetology',
  'Diet',
  'Ear, Nose and Throat Medicine',
  'Emergency Medicine',
  'Endocrinology',
  'Family Medicine',
  'Forensic Medicine',
  'Gastroenterology',
  'General Medicine',
  'Genetics',
  'Geriatrics',
  'Hematology',
  'Hepatology',
  'Hospital Medicine',
  'Immunology',
  'Infectious Diseases',
  'Internal Medicine',
  'Lactation Consultant',
  'Lifestyle Medicine',
  'Mental Health',
  'Midwifery',
  'Nephrology',
  'Neurology',
  'Neurosurgery',
  'Nursing',
  'Nutrition',
  'Obstetrics/Gynecology',
  'Occupational Medicine',
  'Oncology',
  'Ophthalmology',
  'Orthopedics',
  'Osteopathy',
  'Pain Medicine',
  'Pediatrics',
  'Physical Rehabilitation Medicine',
  'Physiotherapy / Physical Therapy (PT)',
  'Plastic Surgery',
  'Podiatry',
  'Preventative Medicine',
  'Psychiatry',
  'Psychology',
  'Psychotherapy',
  'Public Health',
  'Pulmonology',
  'Radiology',
  'Rheumatology',
  'Sport Medicine',
  'Substance Use Disorder',
  'Surgery',
  'Toxicology',
  'Urology',
  'Vascular Medicine',
  'Veterinary Medicine',
  'Wound Medicine',
  'Other Specialties'
];

const ehrSystems = [
  'Allscripts Professional',
  'Athenahealth',
  'CareCloud',
  'Cerner/Oracle',
  'Charm Health',
  'ChartJoy',
  'Coviu',
  'CPSI',
  'CureMD',
  'DrChrono',
  'eClinicalWorks',
  'Elation',
  'eMDs',
  'Epic',
  'Full Script',
  'GE Healthcare',
  'Ggastro',
  'Greenway',
  'Kareo',
  'Medhost',
  'Meditech',
  'ModMed',
  'NextGen',
  'Opus',
  'Practice Fusion',
  'Touchworks',
  'Other EHR'
];

const SpecialtySelector = ({specialtyInfo, onCancel, onContinue }) => {
  const analyticsService = StandardAnalyticsService.shared;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedSpecialty, setSelectedSpecialty] = useState('');
  const [otherSpecialty, setOtherSpecialty] = useState('');
  const [organization, setOrganization] = useState('');
  const [selectedEHR, setSelectedEHR] = useState('');
  const [otherEHR, setOtherEHR] = useState('');
  const [experimentEnabled, setExperimentEnabled] = useState(specialtyInfo.experimentEnabled || false);
  const [token, setToken] = useState('');
  const [key, setKey] = useState('');

  const handleSpecialtyChange = (event) => {
    const value = event.target.value;
    setSelectedSpecialty(value);
    if (value !== 'Other Specialties') {
      setOtherSpecialty('');
    }
  };

  const handleEHRChange = (event) => {
    const value = event.target.value;
    setSelectedEHR(value);
    if (value !== 'Other EHR') {
      setOtherEHR('');
    }
  };

  const handleOnContinue = () => {
    if (onContinue)
      onContinue({ 
        specialty: otherSpecialty || selectedSpecialty, 
        organization, 
        ehr: otherEHR || selectedEHR,
        experimentEnabled
      },experimentEnabled);
  };

  const handleOtherSpecialtyChange = (event) => {
    setOtherSpecialty(event.target.value);
  };

  const handleOrganizationChange = (event) => {
    setOrganization(event.target.value);
  };

  const handleOtherEHRChange = (event) => {
    setOtherEHR(event.target.value);
  };

  const handleExperimentToggle = () => {
    setExperimentEnabled(!experimentEnabled);
  };

  useEffect(() => {
    if (specialtyInfo) {
      setOrganization(specialtyInfo.organization);

      if (specialties.includes(specialtyInfo.specialty)) {
        setSelectedSpecialty(specialtyInfo.specialty);
      } else {
        setSelectedSpecialty('Other Specialties');
        setOtherSpecialty(specialtyInfo.specialty);
      }

      if (ehrSystems.includes(specialtyInfo.ehr)) {
        setSelectedEHR(specialtyInfo.ehr);
      } else {
        setSelectedEHR('Other EHR');
        setOtherEHR(specialtyInfo.ehr);
      }
    }
    
  }, [specialtyInfo]);


  useEffect(() => {
    analyticsService.trackScreen(Analytics.Category.USER, Analytics.ScreenName.PROFILE);

    async function init(){
      let tk = await getAuthToken();
      console.log(tk);
      setKey(getURLToken(tk));
      let t = await getUserProfile();
      let user_id = t.uid || t.id
      setToken('https://'+window.location.hostname+'?intake='+user_id+'&voice=true');

    }
    init()
  },[]);

  return (
    <div className="modal modal-open">
      <div className={`modal-box h-max ${!isMobile ? 'ml-52' : ''}`}>
        <h1 className="text-xl font-bold mb-4">Select your specialty, EHR system, and enter your organization so we can personalize your note templates.</h1>

        {/* Experiment Toggle Switch */}


        <div className="form-control w-full max-w-xs mb-4">
          <select 
            className="select select-bordered"
            value={selectedSpecialty}
            onChange={handleSpecialtyChange}
          >
            <option disabled value="">Select a specialty</option>
            {specialties.map((specialty, index) => (
              <option key={index} value={specialty}>{specialty}</option>
            ))}
          </select>
        </div>

        {selectedSpecialty === 'Other Specialties' && (
          <div className="form-control w-full max-w-xs mb-4">
            <input
              type="text"
              className="input input-bordered"
              placeholder="Please specify"
              value={otherSpecialty}
              onChange={handleOtherSpecialtyChange}
            />
          </div>
        )}

        <div className="form-control w-full max-w-xs mb-4">
          <select 
            className="select select-bordered"
            value={selectedEHR}
            onChange={handleEHRChange}
          >
            <option disabled value="">Select an EHR system</option>
            {ehrSystems.map((ehr, index) => (
              <option key={index} value={ehr}>{ehr}</option>
            ))}
          </select>
        </div>

        {selectedEHR === 'Other EHR' && (
          <div className="form-control w-full max-w-xs mb-4">
            <input
              type="text"
              className="input input-bordered"
              placeholder="Please specify"
              value={otherEHR}
              onChange={handleOtherEHRChange}
            />
          </div>
        )}

        <div className="form-control w-full max-w-xs mb-4">
          <input
            type="text"
            className="input input-bordered"
            placeholder="Organization"
            value={organization}
            onChange={handleOrganizationChange}
          />
        </div>
        <div className="form-control mb-4">
          <label className="label cursor-pointer">
            <span className="label-text">🧪 Health Note Labs (New Experimental Features)</span>
            <input type="checkbox" className="toggle btn-hn-labs toggle-primary" checked={experimentEnabled} onChange={handleExperimentToggle} />
          </label>
          <input className='text-wrap bg-gray-100 m-2 text-sm' value = {token}>
          </input>
          <input className='text-wrap bg-gray-100 m-2 text-sm' value = {key}></input>
        </div>
        <div className="modal-action">
          <button className="btn btn-accent" onClick={handleOnContinue}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default SpecialtySelector;

