import React from 'react';

const AnimatedWaveLine = () => {
  return (
    <div className="w-full h-[200px] overflow-hidden absolute bottom-0 left-0 -z-10">
      <div className="">
        <svg className="w-full h-[200px]" viewBox="0 0 1440 1080" preserveAspectRatio="none">
          <defs>
            <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#47E6D8" />
              <stop offset="100%" stopColor="#47E6D8" />
            </linearGradient>
          </defs>
          <path
            d="M0,540 C240,270 480,810 720,540 C960,270 1200,810 1440,540 C1680,270 1920,810 2160,540"
            fill="none"
            stroke="url(#lineGradient)"
            strokeWidth="16"
          >
            <animate
              attributeName="d"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0; 0.5; 1"
              values="
                M0,540 C240,270 480,810 720,540 C960,270 1200,810 1440,540 C1680,270 1920,810 2160,540;
                M0,540 C240,810 480,270 720,540 C960,810 1200,270 1440,540 C1680,810 1920,270 2160,540;
                M0,540 C240,270 480,810 720,540 C960,270 1200,810 1440,540 C1680,270 1920,810 2160,540
              "
              begin="0s"
            />
            <animateTransform
              attributeName="transform"
              type="translate"
              from="-720 0"
              to="0 0"
              dur="2s"
              repeatCount="indefinite"
              begin="0s"
            />
            <animate
              attributeName="opacity"
              from="0"
              to="1"
              dur="3s"
              fill="freeze"
              begin="0s"
            />
          </path>
        </svg>
      </div>
    </div>
  );
};

export default AnimatedWaveLine;