import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AudioLines, Mic, Loader, Settings } from 'lucide-react';
import { RealtimeClient } from './voice_client.js';
import { Player } from './player.js';
import { Recorder } from './recorder.js';
import AnimatedWaveLine from './AnimatedWaveLine';
import { getKeys, logError, promiseRunTextLLM, runTextLLM, runTextLLMMini, runLLMMiniReasonForVisitQuestion, getAuth, addPatient, getUrlParameter, addIntakePatient,htmlToText, convertMarkdownToHtml } from './db';


import moment from "moment";
function formatDateWithMoment(date) {
    return moment(date).format("MM/DD/YY hh:mm A");
}

let DEBOUNCE_TIMER = null;

let audioRecorder = null;
let recordingActive = false;
let audioPlayer = null;
let buffer = new Uint8Array();

let isCompleting = false;
let CURRENT_CONVERSATION = [];

const client = new RealtimeClient({
    endpoint: 'hn-openai-prod-east2.openai.azure.com',
    apiKey: '40e39516be00493fb5dab7f68b09d836',
    deployment: 'gpt-4o-realtime-preview',
    dangerouslyAllowAPIKeyInBrowser: true,
    debug: false
});

function concatenateConversationToString(currentConversation) {
    return currentConversation
        .map(item => `${JSON.stringify(item.role)}: ${JSON.stringify(item.text)} })`)
        .join('\n');
}

function processAudioRecordingBuffer(data) {
    if (recordingActive) {
        client.appendInputAudio(data);
    }
}

async function stopRecording() {
    recordingActive = false;
    if (audioRecorder) {
        audioRecorder.stop();
    }
    if (audioPlayer) {
        audioPlayer.clear();
    }
}
async function resetAudio(startRecording) {
    recordingActive = false;
    if (audioRecorder) {
        audioRecorder.stop();
    }
    if (audioPlayer) {
        audioPlayer.clear();
    }
    audioRecorder = new Recorder(processAudioRecordingBuffer);
    audioPlayer = new Player();
    audioPlayer.init(24000);

    if (startRecording) {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioRecorder.start(stream);
    }
}

const IntakeVoice = () => {
    const [recognizedText, setRecognizedText] = useState('');
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [showMic, setShowMic] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [result, setResult] = useState('');
    const [isGenerating , setIsGenerating] = useState(false);
    const [responseMode, setResponseMode] = useState('wait');
    //     const [instructions, setInstructions] = useState(`You are an AI medical assistant, follow the <instructions>
    // Step 0: Do not respond or ask question if you don't receive a answer.
    // Step 1: when you first response say
    // "Hi, brings you in to see the doctor today? Please speak freely and include as many details as you want the doctor to know. I will ask you about 6 follow up questions to get more information."
    // "Hi, Dr. Kim, wanted me to check in on you on how you are feeling since your visit last month. I will ask you about 6 follow up questions to get more information."

    // Step 2: Response with very short follow up questions related to the issue. Limit to 1 question and 1 kind of answer. Give short examples answer.
    // Step 3: Only respond if you hear complete answer to your question.
    // Step 4: Ask not more than 6 follow up questions.
    // Final Step: When you finish asking questions respond with "Thank you for providing this information. I'm going to  send your information to the doctor, and we're looking forward to seeing you at the visit"
    // </instructions>`);
    //    "Hi, what brings you in to see the doctor today? Please speak freely and include as many details as you want the doctor to know. I will ask you about 6 follow up questions to get more information."

    const [instructions, setInstructions] = useState(`You are an AI medical assistant that will respond in the native language of the speaker, do not respond in spanish if you don't hear spanish follow the <rules> 
1. Speak fast, wait to respond.
2. If answer is not clear ask for clarification 
3. when you first response ask
"Hi! What brings you to the doctors office? I’ll share all of these details with your healthcare provider.“
4. Response with very short follow up questions related to the issue. Limit to 1 question and 1 kind of answer. Give short examples answer.
5. Ask not more than 6 follow up questions.
    Before Final Step: When you finish asking questions respond with "Is there anything else you would like to share with your healthcare provider?“ wait for response before final step.
Final Step:  Say "Thank you for providing this information. I'm going to send your information to the doctor, and we're looking forward to seeing you at the visit" and say "Goodbye" in english.
    </rules>`);

    const [, forceUpdate] = useState();

    const audioRef = useRef(null);
    const clientRef = useRef(null);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Scroll to bottom whenever conversation updates
    useEffect(() => {
        scrollToBottom();
    }, [CURRENT_CONVERSATION]);


    useEffect(() => {
        async function init() {
            // Initialization logic here if needed
        }
        init();

        return () => {
            if (clientRef.current) {
                clientRef.current.disconnect();
            }
        };
    }, []);

    const handleMicClick = async function (e) {

        if (clientRef.current) {
            return
        }


        setIsLoading(true);
        setIsAudioPlaying(false);
        await resetAudio(true);

        audioPlayer.setOnPlaybackFinished(() => {
            recordingActive = true;
            console.log("playback finished");
            if((responseMode === 'listen' || responseMode === 'wait') && !isCompleting){
                setResponseMode('talk');
            }
    
        })
        clientRef.current = client;

        try {
            client.updateSession({ instructions: instructions, temperature: 0.9 });
            client.updateSession({ voice: 'alloy' });
            client.updateSession({
                turn_detection: { type: 'server_vad' },

                input_audio_transcription: { model: 'whisper-1' },
            });

            client.on('conversation.updated', ({ item, delta }) => {
                //console.log("update tiems: ", item);
                setTimeout(() => {
                    if (delta?.audio) {
                        const binary = atob(delta.audio);
                        const bytes = Uint8Array.from(binary, (c) => c.charCodeAt(0));
                        const pcmData = new Int16Array(bytes.buffer);
    
                        audioPlayer.play(pcmData);
                        setIsLoading(false);
                        setIsAudioPlaying(true);
                        if(responseMode === 'talk' || responseMode === 'wait'){
                            setResponseMode('listen');
                        }
                        recordingActive = false;
                    }
                    if (delta?.text) {
                        setRecognizedText(prev => prev + delta.text);
                    }
                }, 1000);

            });

            client.on('conversation.item.truncate', ({ item }) => {
                console.log("Truncate: ", item);
            })



            client.on('conversation.item.completed', ({ item }) => {
                console.log("Item: ", item);
                if (item.type === 'message') {
                    if (item.role === 'assistant' || item.role === 'user') {
                        setTimeout(() => {



                            if(item.role === 'user'){
                                if(item.formatted.transcript.trim().length > 3){
                                    console.log("Stopping Playing", item.formatted.transcript);
                                    //audioPlayer.clear();
                                }
                            }
                            
                            CURRENT_CONVERSATION.push({ role: item.role, text: item.formatted.transcript });



                            if (item.formatted.transcript && (item.formatted.transcript.toLowerCase().includes("information to the doctor") || item.formatted.transcript.toLowerCase().includes("goodbye"))) {
                                isCompleting = true;
                                setIsGenerating(true);
                                setResponseMode('end');


                                setTimeout(async () => {
                                    setResponseMode('end');

                                    console.log("Stopping recording");
                                    await stopRecording();
                                    setIsAudioPlaying(false);
                                    client.disconnect();

                                    setResponseMode('end');


                                    let llmResponse = await runTextLLMMini('Summarize the <conversation> into english short paragraph for the doctor. ' + concatenateConversationToString(CURRENT_CONVERSATION) + '</conversation>.', function (inProgressResponse) {
                                        setResult(inProgressResponse);
                                        setResponseMode('end');

                                    })

                                    setResult(llmResponse);
                                    setIsGenerating(false);
                                    setResponseMode('end');


                                    const newPatient = {
                                        name: '',
                                        clinicalNote: "Placeholder",
                                        appointmentTimestamp: new Date().toISOString(),
                                        date: formatDateWithMoment(new Date()),
                                        history: htmlToText(convertMarkdownToHtml(llmResponse)),
                                        language: 'en',
                                        ehrId: '',
                                        isDictation: false
                                    };



                                    const id = await addIntakePatient(getUrlParameter('intake'),newPatient);


                                }, 10000);
                            }
                        }, 400);

                        if (item.role === 'assistant') {
                            clearTimeout(DEBOUNCE_TIMER);
                            DEBOUNCE_TIMER = setTimeout(() => {
                                //recordingActive = true;
                                forceUpdate({});
                                setTimeout(() => {
                                    scrollToBottom();
                                },100);    

                            }, 2000);


                        } else {
                            clearTimeout(DEBOUNCE_TIMER);
                            DEBOUNCE_TIMER = setTimeout(() => {
                                forceUpdate({});
                                setTimeout(() => {
                                    scrollToBottom();
                                },100);
    
                            }, 800);
                        }
                    }
                }
            });



            await client.connect();

            client.sendUserMessageContent([{ type: 'input_text', text: `Hi` }]);
            setShowMic(true);
        } catch (error) {
            console.error('Connection error:', error);
            setIsLoading(false);
        }
    };

    const sendAudioData = useCallback((audioData) => {
        if (clientRef.current) {
            clientRef.current.send('input_audio_buffer.append', {
                audio: audioData
            });
        }
    }, []);

    const toggleInstructions = () => {
        setShowInstructions(!showInstructions);
    };

    return (
        <div className="intake-voice p-4 bg-white">
                    <div>
                    <img src="https://s3.amazonaws.com/ui-demo.healthnote.com/images/client/voice-intake-logo/voice-intake-demo-logo.png" alt="HealthNote" className="w-full mt-3 mb-4 ml-2 h-20 max-w-[250px] z-10 absolute top-2 left-2 object-cover" />
                    </div>

            {!showInstructions && !isAudioPlaying && !result && !isGenerating && (
                <div>
                    <div className='left-[calc(50vw-180px)] text-xl font-bold absolute top-[250px]'>Tap below to start your previsit intake.</div>
                    <button
                        onClick={handleMicClick}
                        className={`
                    ${isLoading || isAudioPlaying ? 'animate-pulse animate-pulse-grow' : ''}
                    bg-orange-400 hover:bg-orange-300
                    text-white font-bold py-2 px-4 rounded flex items-center
                    transition-all duration-300 ease-in-out left-[calc(50vw-50px)] w-[120px] top-[300px] absolute
                `}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Loader className="mr-2 animate-spin" />
                        ) : isAudioPlaying ? (
                            <AudioLines className="mr-2" />
                        ) : (
                            <Mic className="mr-2" />
                        )}
                        {isLoading ? 'Loading...' : isAudioPlaying ? 'Listening...' : 'Start'}
                    </button>
                </div>
            )}


            <button
                onClick={toggleInstructions}
                className="bg-transparent hover:bg-gray-300  text-gray-800 font-bold py-2 px-4 rounded absolute right-0 top-1"
            >
                <Settings className="" />

            </button>

            {showInstructions && (
                <div className="mb-4">
                    <label htmlFor="instructions" className="block mb-2 text-sm font-medium">Instructions:</label>
                    <textarea
                        id="instructions"
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                        className="w-full p-2 border rounded h-64 resize-vertical"
                        placeholder="Enter instructions here"
                    />
                </div>
            )}

            <audio id="audioPlayer" ref={audioRef}></audio>
            {isAudioPlaying && (
                <AnimatedWaveLine audioData={buffer} sendAudioData={sendAudioData} />

            )}

            {CURRENT_CONVERSATION.length > 0 && isAudioPlaying && (
                <div className="text-2xl h-[400px] top-[110px] left-0 p-2 w-full overflow-y-auto absolute z-10">
                    {CURRENT_CONVERSATION.map((message, index) => (
                        <div key={index} className="mb-2 whitespace-pre-wrap m-1">
                            {message.text.length > 0 && (
                                <strong>{message.role === 'assistant' ? 'Clinic: ' : 'Patient: '}</strong>
                            )}
                            {message.text}
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
            )}
            {responseMode === 'talk' && (<div className='text-4xl font-extrabold text-color-57d2c8 absolute bottom-10 left-[calc(50vw-170px)]'>Go ahead and speak</div>)}
            {responseMode === 'listen' && (<div className='text-4xl font-extrabold text-color-57d2c8 absolute bottom-10 left-[calc(50vw-100px)]'>Please listen</div>)}
            <div className='absolute top-[110px] left-0 p-2'>
            {result.length > 0 && !isAudioPlaying && (
                <div className='p-2'>
                    <h2 className="text-3xl font-bold">Summary</h2>
                    <p className="whitespace-pre-line text-2xl"  dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(result) }}></p>
                </div>
            )}
            {isGenerating && (
                <Loader className="mr-2 animate-spin" />
            )}
            </div>
            
        </div>
    );
};

// Add this style block at the end of your component file
const styles = `
@keyframes pulseGrow {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
}

.animate-pulse-grow {
    animation: pulseGrow 2s infinite;
}
`;

export default () => (
    <>
        <style>{styles}</style>
        <IntakeVoice />
    </>
);