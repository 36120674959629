// media-mock.js

/**
 * Creates mock overrides for media-related browser APIs
 * Includes mocked AudioContext with Analyser node functionality
 */
// messageHandler -> MediaRecorder -> Stream -> AnalyserNode's _audioBuffer
/*

  window.parent.postMessage(
    {
      type: "media-mock-ready",
      capabilities: {
        audio: true,
        video: true,
        recording: true,
      },
    },
    "*"
  );
      window.parent.postMessage(
        {
          type: "mediaRecorder",
          command: "start",
          data: {
            timeslice,
            options: {
              mimeType: this.mimeType,
              videoBitsPerSecond: this.videoBitsPerSecond,
              audioBitsPerSecond: this.audioBitsPerSecond,
            },
          },
        },
        "*"
      );
      // Notify parent window
      window.parent.postMessage(
        {
          type: "mediaRecorder",
          command: "stop",
        },
        "*"
      );
  // Add message event listener to handle incoming audio data
  window.addEventListener("message", (event) => {
    const handler = messageHandlers[event.data?.type];
    if (handler) {
      handler(event.data);
    }
  });

      window.parent.postMessage(
        {
          type: "mediaRecorder",
          command: "pause",
        },
        "*"
      );

      window.parent.postMessage(
        {
          type: "mediaRecorder",
          command: "resume",
        },
        "*"
      );

      window.parent.postMessage(
        {
          type: "stream",
          command: "create",
          streamId: this.id,
          tracks: tracks.map((t) => ({ id: t.id, kind: t.kind })),
        },
        "*"
      );

*/
function initMediaMock() {
  // Ensure we're in a browser environment
  if (typeof window === "undefined") {
    console.warn("Browser environment not detected");
    return;
  }

  let currentStream = null;
  let currentEnqueueData = null;

  function createMockStreamWithScriptProcessor() {
    const audioCtx = new AudioContext();
    const bufferSize = 4096; // You can adjust this value
    const scriptNode = audioCtx.createScriptProcessor(bufferSize, 0, 1); // 0 input channels, 1 output channel
  
    const dest = audioCtx.createMediaStreamDestination();
    scriptNode.connect(dest);
  
    const audioTrack = dest.stream.getAudioTracks()[0];

    // Add track ended listener


    
    // Queue to hold audio data
    const dataQueue = [];
  
    // Function to enqueue data
    function enqueueData(float32Array) {
      dataQueue.push(float32Array);
    }
  
    scriptNode.onaudioprocess = function(audioProcessingEvent) {
      const outputBuffer = audioProcessingEvent.outputBuffer;
      const outputData = outputBuffer.getChannelData(0);
  
      if (dataQueue.length > 0) {
        const nextData = dataQueue.shift();
        outputData.set(nextData.subarray(0, outputData.length));
      } else {
        // Output silence if there's no data
        outputData.fill(0);
      }
    };
  
    // Start the audio context if it's suspended
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }
  
    return { stream: dest.stream, enqueueData };
  }

  // Mock getUserMedia function
  function mockGetUserMedia(constraints) {
    return new Promise((resolve, reject) => {
      if (!constraints.audio) {
        reject(new Error("Audio constraints required"));
        return;
      }

      // Create new stream if none exists
      if (!currentStream) {
        const { stream, enqueueData } = createMockStreamWithScriptProcessor();
        currentStream = stream;
        currentEnqueueData = enqueueData;
      }
      else{
        currentStream.getTracks().forEach(track => track.stop());
        currentStream = null;
        currentEnqueueData = null;

        const { stream, enqueueData } = createMockStreamWithScriptProcessor();
        currentStream = stream;
        currentEnqueueData = enqueueData;
      }

      resolve(currentStream);
    });
  }

  // Message handling setup for receiving audio data
  const messageHandlers = {
    audioData: (data) => {
      if (!currentEnqueueData) {
        console.warn("No active stream to enqueue data to");
        return;
      }

      const audioDataArray = [];
      for (let p in data.audioData) {
        audioDataArray.push(data.audioData[p]);
      }
      
      const audioDataCopy = new Float32Array(audioDataArray);
      currentEnqueueData(audioDataCopy);
    },
  };

  // Add message event listener to handle incoming audio data
  window.addEventListener("message", (event) => {
    const handler = messageHandlers[event.data?.type];
    if (handler) {
      handler(event.data);
    }
  });

  // Setup the mock on navigator if it doesn't exist
  if (typeof window !== "undefined") {
    if (!window.navigator.mediaDevices) {
      window.navigator.mediaDevices = {};
    }
    window.navigator.mediaDevices.getUserMedia = mockGetUserMedia;
  }

  // Store the original MediaRecorder
  const OriginalMediaRecorder = window.MediaRecorder;

  // Create mock MediaRecorder class
  class MockMediaRecorder extends OriginalMediaRecorder {
    constructor(stream, options) {
      super(stream, options);
      this._isStopped = false;
    }
    start(timeslice) {
      window.parent.postMessage(
        {
          type: "stream",
          command: "create",
        },
        "*"
      );
      this._isStarted = true;
      this._isStopped = false;
      super.start(timeslice);
    }
  

    stop() {
      window.parent.postMessage(
        {
          type: "stream",
          command: "stop"
        },
        "*"
      );

      this._isStopped = true;
      super.stop();
    }

    get isStopped() {
      return this._isStopped;
    }
  }

  // Replace the global MediaRecorder
  window.MediaRecorder = MockMediaRecorder;

  // Return cleanup function
  return () => {
    if (currentStream) {
      currentStream.getTracks().forEach(track => track.stop());
      currentStream = null;
      currentEnqueueData = null;
    }
  };

  
}

// Export as ES module
export default initMediaMock;
export { initMediaMock };
